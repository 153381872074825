import React, { Component, ReactNode } from 'react';
import { logError } from '../../../helpers/logger.helper';
import {
  ErrorBoundaryProps,
  ErrorBoundaryState
} from './page-error-boundary.component';
import ErrorPage from '../../../pages/auth/error';

export class AuthErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }
  componentDidCatch(
    error: Error | { name: string; message: string },
    errorInfo: never
  ): void {
    // You can also log the error to an error reporting service
    logError(error, errorInfo);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
