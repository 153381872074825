import { OS } from '@bonsai-components/detect-os';
import { PureFunction } from '@bonsai-components/utility-types';
import { useMemo } from 'react';
import { MarkdownString } from '../../../@types';
import { FetchResponse, useFetch } from '../../../hooks/use-fetch.hooks';

const MASKED_TOKEN = '<your-token-is-filled-when-using-the-copy-button>';
export const enum InstallKind {
  CODE,
  MARKDOWN
}

export type PackageChannel = 'staging' | 'stable';

type PackageInstallCommandProps = {
  uri: string;
  token: string;
  fileName: string;
  version: string;
  environment?: PackageChannel;
};

type PackageInstallCode = {
  kind: InstallKind.CODE;
  command: (args?: { maskToken: boolean }) => string;
};

type PackageInstallMarkdown = {
  kind: InstallKind.MARKDOWN;
  command: MarkdownString;
};

export type PackageInstallCommandResult =
  | PackageInstallCode
  | PackageInstallMarkdown;

export type PackageInstallCommand = {
  key: string;
  label: string;
  command: PureFunction<
    PackageInstallCommandProps,
    PackageInstallCommandResult
  >;
  description?: MarkdownString;
};

export type Distribution = {
  key: string;
  label: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  installCommands: PackageInstallCommand[];
  fileExtension: string;
};

export const getPowerShellCommand: PureFunction<
  PackageInstallCommandProps,
  PackageInstallCode
> = ({ uri, token, fileName }) => {
  return {
    kind: InstallKind.CODE,
    command: ({ maskToken }) => `$headers=@{}
$headers.Add("Authorization", "Bearer ${maskToken ? MASKED_TOKEN : token}")
$response = Invoke-RestMethod -Uri '${uri}' -OutFile ${fileName} -Method GET -Headers $headers`
  };
};

export const getCurlCommand: PureFunction<
  PackageInstallCommandProps,
  PackageInstallCode
> = ({ uri, token, fileName }) => {
  return {
    kind: InstallKind.CODE,
    command: ({ maskToken }) => `curl --request GET \\
    --output ${fileName} \\
    --url '${uri}' \\
    --header 'Authorization: Bearer ${maskToken ? MASKED_TOKEN : token}'`
  };
};

export const getFileName: PureFunction<{ fileExtension?: string }, string> = ({
  fileExtension
}) => {
  return `mod${fileExtension ? `.${fileExtension}` : ''}`;
};

export const getDownloadUrl: PureFunction<
  { baseUrl: string; operatingSystem: string; environment: PackageChannel },
  string
> = ({ baseUrl, operatingSystem, environment }) =>
  `${baseUrl}/cli/download?operatingSystem=${operatingSystem}&environment=${environment}`;

export const useLatestVersion: PureFunction<
  {
    accessToken: unknown | string;
    apiGatewayUrl: string;
    operatingSystem: PackageSupportedOS;
  },
  FetchResponse<{ staging: string; stable: string }>
> = ({ apiGatewayUrl, accessToken, operatingSystem }) => {
  /**
   * Not memoizing the headers will cause an infinite loop because a new object
   * is created on every render.
   */
  const options = useMemo<RequestInit>(
    () => ({
      headers: new Headers({
        Authorization: 'Bearer ' + accessToken
      })
    }),
    [accessToken]
  );
  const apiEndpoint = new URL(`${apiGatewayUrl}/cli/version/latest`);
  apiEndpoint.searchParams.append('operatingSystem', operatingSystem);
  return useFetch(apiEndpoint.toString(), options);
};

type PackageSupportedOS = 'macos' | 'linux' | 'windows';

export const determineOperatingSystem: PureFunction<OS, PackageSupportedOS> = (
  os
) => {
  switch (os) {
    case 'mac':
      return 'macos';
    case 'windows':
      return 'windows';
    default:
      return 'linux';
  }
};
