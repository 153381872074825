import { Duration, formatDuration, intlFormat } from 'date-fns';
import { getCurrentLocale } from './i18n.helper';

/**
 * @name parseISODuration
 * @summary Parse ISO duration string
 * @see https://github.com/date-fns/date-fns/pull/2302/files#diff-74eac2a2527c0ee102cbc46854f1f06ada157b35f153edc95c30719d362a8da2
 * @description Parse the given string in ISO 8601 duration format and return an instance of Duration.
 * Function accepts complete ISO 8601 formats.
 * ISO 8601: http://en.wikipedia.org/wiki/ISO_8601
 *
 * If the argument isn't a string, the function cannot parse the string or
 * the values are invalid, it returns an empty object `{}`.
 * @param {string} argument - the value to convert
 * @returns {Duration | {}} the parsed duration or an empty object
 * @throws {TypeError} 1 argument required
 * @example
 * // Convert string 'P1DT5M30S' to duration:
 * var result = parseISODuration('P1DT5M30S')
 * //=> { days: 1, minutes: 5, seconds: 30 }
 */
export const parseISODuration = (argument: string): Duration => {
  if (!argument || argument === '') {
    return {
      years: 0,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  }
  const nr = '\\d+(?:[\\.,]\\d+)?';
  const dateRegex = '(' + nr + 'Y)?(' + nr + 'M)?(' + nr + 'D)?';
  const timeRegex = 'T(' + nr + 'H)?(' + nr + 'M)?(' + nr + 'S)?';
  const durationRegex = new RegExp('P' + dateRegex + '(?:' + timeRegex + ')?');

  if (
    !(
      typeof argument === 'string' ||
      Object.prototype.toString.call(argument) === '[object String]'
    )
  ) {
    return {};
  }

  const match = argument.match(durationRegex);
  if (!match) {
    return {};
  }

  // at least one part must be specified
  if (
    !match[1] &&
    !match[2] &&
    !match[3] &&
    !match[4] &&
    !match[5] &&
    !match[6]
  ) {
    return {};
  }

  const duration: Duration = {};
  if (match[1]) duration.years = parseFloat(match[1]);
  if (match[2]) duration.months = parseFloat(match[2]);
  if (match[3]) duration.days = parseFloat(match[3]);
  if (match[4]) duration.hours = parseFloat(match[4]);
  if (match[5]) duration.minutes = parseFloat(match[5]);
  if (match[6]) duration.seconds = Number(parseFloat(match[6]).toFixed(2));
  return duration;
};

/**
 * Takes date like string (YYYY-MM-dd) and returns the localDateString but
 * normalizes it to the current timezone.
 */
export const toLocalNormalizedDateString = (
  dateLikeString: string,
  options?: Intl.DateTimeFormatOptions
) => {
  const date = new Date(dateLikeString);
  return intlFormat(date.getTime() - date.getTimezoneOffset() * -60000, {
    locale: getCurrentLocale(),
    ...options
  });
};

/**
 *
 * Convert milliseconds to a `Duration` object
 */
export const millisecondsToDuration = (milliseconds: number): Duration => {
  const duration: Duration = {
    years: 0,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };
  duration.years = Math.floor(milliseconds / 31536000000);
  milliseconds -= duration.years * 31536000000;
  duration.months = Math.floor(milliseconds / 2592000000);
  milliseconds -= duration.months * 2592000000;
  duration.weeks = Math.floor(milliseconds / 604800000);
  milliseconds -= duration.weeks * 604800000;
  duration.days = Math.floor(milliseconds / 86400000);
  milliseconds -= duration.days * 86400000;
  duration.hours = Math.floor(milliseconds / 3600000);
  milliseconds -= duration.hours * 3600000;
  duration.minutes = Math.floor(milliseconds / 60000);
  milliseconds -= duration.minutes * 60000;
  duration.seconds = Math.floor(milliseconds / 1000);
  milliseconds -= duration.seconds * 1000;

  return duration;
};

/**
 * Convert ISO duration string into milliseconds for the purposes of sorting
 * and other comparisons.
 */
const durationToMilliseconds = (duration: Duration): number => {
  return (
    (duration.years || 0) * 31536000000 +
    (duration.months || 0) * 2592000000 +
    (duration.weeks || 0) * 604800000 +
    (duration.days || 0) * 86400000 +
    (duration.hours || 0) * 3600000 +
    (duration.minutes || 0) * 60000 +
    (duration.seconds || 0) * 1000
  );
};

/**
 *
 * Convert a ISO Duration string into a `Duration` object and then convert it
 * to milliseconds
 */
export const getDurationAsMilliseconds = (time: string): number => {
  const format = parseISODuration(time);
  return durationToMilliseconds(format);
};

const formatDistanceLocale = {
  xSeconds: '{{count}}s',
  xMinutes: '{{count}}m',
  xHours: '{{count}}h'
};

const shortEnLocale = {
  formatDistance: (token, count) =>
    formatDistanceLocale[token]?.replace('{{count}}', count)
};

/**
 * Convert either a duration string or object into human readable format.
 */
export const formatRelativeTime = (value: string | Duration) => {
  let duration: Duration;
  if (typeof value === 'string') {
    duration = parseISODuration(value);
  } else {
    duration = value;
  }

  return formatDuration(duration, {
    locale: shortEnLocale
  });
};

// a method that will take two Date objects and find the difference between them as a Duration object
export const getDurationBetweenDates = (
  start: Date | string,
  end: Date | string
): number => {
  const diff = new Date(end).getTime() - new Date(start).getTime();
  return diff;
};

const FULL_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  dateStyle: 'full',
  timeStyle: 'full'
};

export const formatFullDate = (currentLocale, date) =>
  new Intl.DateTimeFormat(currentLocale, FULL_DATE_OPTIONS).format(date);

const BASE_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZoneName: 'longOffset',
  hourCycle: 'h23'
};

const BASE_DATE_OPTIONS_UTC: Intl.DateTimeFormatOptions = {
  ...BASE_DATE_OPTIONS,
  timeZone: 'UTC',
  timeZoneName: 'short'
};

/**
 * Used in converting intl date to short ISO date
 * 04/21/2023, 12:15:16 GMT-07:00 -> 2023-04-21 12:15:16 -0700
 */
const REGEX_SHORT_DATE_COMPONENTS =
  /(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+) GMT(.*):(\d+)/;

/**
 * Used in converting intl date to short ISO UTC date
 * 04/21/2023, 19:15:16 UTC -> 2023-04-21 19:15:16 UTC
 */
const REGEX_SHORT_UTC_DATE_COMPONENTS =
  /(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+)/;

/**
 * Formats a date into a short ISO date string.
 * @param {Date} date - The date to format.
 * @returns {string} The formatted short ISO date string.
 */
export const formatShortISODate = (date) =>
  new Intl.DateTimeFormat('en-US', BASE_DATE_OPTIONS)
    .format(date)
    .replace(REGEX_SHORT_DATE_COMPONENTS, '$3-$1-$2 $4:$5:$6 $7$8');

/**
 * Formats a date in short ISO format (YYYY-MM-DD HH:mm:ss) in UTC timezone.
 *
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date string.
 */
export const formatShortISODateUTC = (date) =>
  new Intl.DateTimeFormat('en-US', BASE_DATE_OPTIONS_UTC)
    .format(date)
    .replace(REGEX_SHORT_UTC_DATE_COMPONENTS, '$3-$1-$2 $4:$5:$6');
