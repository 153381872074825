import { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import {
  Box,
  CardHeader,
  IconButton,
  SvgIcon,
  cardHeaderClasses
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useContext } from 'react';
import {
  DownloadManifest,
  DownloadsContext,
  ManifestKind
} from '../../contexts/downloads.context';
import {
  ModArrowCircleDown,
  ModArrowCircleUp,
  ModCancelIcon,
  ModDoubleArrowIcon
} from '../../icons/moderne-icons';
import {
  CenteredBox,
  FlexBox
} from '../styled-components/layouts/layouts.styled';
import { ExpandCollapse } from '../utilities/expand-collapse/expand-collapse.component';
import { DataTableDownloadable } from './data-table-downloadable.component';
import { DownloadStateIcon, Downloadable } from './downloadable.component';

type DownloadManagerProps = {
  downloadManifests?: DownloadManifest[];
};

const DownloadManagerContainer: FunctionComponentWithChildren = ({
  children
}) => {
  const { minimized, toggleMinimize, downloads, removeDownload } =
    useContext(DownloadsContext);

  const confirm = useConfirm();

  const handleDismissDownloads = () =>
    confirm({
      title: 'Dismiss downloads',
      description: 'Are you sure you want to remove all downloads?'
    }).then(removeAllDownloads);

  const removeAllDownloads = () => {
    downloads.forEach((download) => {
      removeDownload(download.fileName);
    });
  };

  const downloadsInProgress = (
    downloads?.filter((download) => {
      return download.state === 'PENDING';
    }) || []
  ).length;

  const completedWithFailures =
    !downloadsInProgress &&
    (
      downloads?.filter((download) => {
        return download.state === 'FAILURE';
      }) || []
    ).length;

  const overallDownloadState =
    downloadsInProgress > 0
      ? 'PENDING'
      : completedWithFailures
        ? 'FAILURE'
        : 'SUCCESS';

  return (
    <Box
      sx={{
        minWidth: 320,
        maxWidth: 500,
        overflow: 'hidden',
        bgcolor: 'white',
        boxShadow: 6,
        paddingX: 2.5,
        borderColor: (theme) => theme.palette.grey[300],
        borderTopLeftRadius: '1rem',
        borderTopRightRadius: '1rem',
        pointerEvents: 'auto'
      }}>
      <CardHeader
        sx={{
          [`& .${cardHeaderClasses.action}`]: {
            margin: 0,
            alignSelf: 'center',
            marginRight: 0.6
          },
          alignItems: 'center',
          justifyContent: 'center',
          px: 0,
          pt: 2,
          pb: 0
        }}
        avatar={
          <FlexBox
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'grey.125',
              borderRadius: '50%',
              width: (theme) => theme.typography.pxToRem(36),
              height: (theme) => theme.typography.pxToRem(36)
            }}>
            {overallDownloadState === 'PENDING' ? (
              <ModDoubleArrowIcon
                color="primary"
                sx={{
                  fontSize: (theme) => theme.typography.pxToRem(25)
                }}
              />
            ) : (
              <CenteredBox
                sx={{
                  flexDirection: 'column'
                }}>
                <DownloadStateIcon state={overallDownloadState} />
              </CenteredBox>
            )}
          </FlexBox>
        }
        title="Preparing downloads..."
        titleTypographyProps={{ fontWeight: 600, fontSize: '.875rem' }}
        subheader={
          downloadsInProgress > 0
            ? `${downloadsInProgress} in progress`
            : 'Completed'
        }
        subheaderTypographyProps={{ variant: 'caption' }}
        action={
          <Box
            sx={{
              pr: 0.5
            }}>
            <IconButton onClick={handleDismissDownloads} size="small">
              <SvgIcon
                component={ModCancelIcon}
                inheritViewBox
                color={'secondary'}
                sx={{ fontSize: (theme) => theme.typography.pxToRem(32.5) }}
              />
            </IconButton>
            <ExpandCollapse
              variant="icon"
              isExpanded={!minimized}
              sx={{ padding: 0 }}
              expandIcon={
                <ModArrowCircleDown
                  sx={{
                    fontSize: (theme) => theme.typography.pxToRem(32.5)
                  }}
                />
              }
              collapseIcon={
                <ModArrowCircleUp
                  sx={{
                    fontSize: (theme) => theme.typography.pxToRem(32.5)
                  }}
                />
              }
              onClick={toggleMinimize}
              color="inherit"
            />
          </Box>
        }
      />
      {/* Download rows perform the polling so must be rendered or polling does not happen when collapsed */}
      <Box
        sx={{
          height: minimized ? 0 : undefined,
          visibility: minimized ? 'hidden' : 'visible'
        }}>
        {children}
      </Box>
    </Box>
  );
};

export const DownloadManager: FunctionComponent<DownloadManagerProps> = ({
  downloadManifests
}) => (
  <DownloadManagerContainer>
    {downloadManifests.map((manifest, idx) =>
      manifest.kind === ManifestKind.DATA_TABLE ||
      manifest.kind === ManifestKind.DEVCENTER_DATA_TABLE ? (
        <DataTableDownloadable
          key={[manifest.fileName, idx].join(':')}
          manifest={manifest}
        />
      ) : (
        <Downloadable
          key={[manifest.fileName, idx].join(':')}
          manifest={manifest}
        />
      )
    )}
  </DownloadManagerContainer>
);
