import { useRouter } from 'next/router';
import { NonFunctionProperties } from '../@types';
import { RecipeDetailsFragment } from '../__generated__/apollo-hooks';
import { BUILDER_DEFAULT_RIGHT_SIDEBAR_WIDTH } from '../constants/builder';
import { promptForRecipeOverride } from '../helpers/builder/builder-dialog.helpers';
import { cleanLinks } from '../helpers/builder/builder-links.helpers';
import { removeNode } from '../helpers/builder/builder-nodes.helpers';
import {
  addRecipeDetailsFragmentToNode,
  makeParentsCustomRecipe,
  updateCurrentRecipeDetails,
  updateSelectedNodeOptions
} from '../helpers/builder/builder-recipe.helpers';
import {
  createYamlFromNodesAndLinks,
  CreateYamlOptions
} from '../helpers/builder/builder-yaml.helpers';
import { BuilderRecipe, db } from '../helpers/indexedDb.helper';
import {
  GraphLink,
  GraphNodeData,
  ROOT_NODE_ID,
  useRecipeTopologyStore
} from './recipe-topology.store';
import { createTemporalStore } from './store-creators';
import { useUserPreferenceStore } from './user-preference.store';

/**
 * Wrap a function so that the loading state is set before the call and reset after
 */
const withLoading = <T extends (...args: unknown[]) => Promise<void>>(
  fn: T
): T => {
  return (async (...args: Parameters<T>): Promise<void> => {
    const setIsLoading = (isLoading: boolean) => {
      const currentLoading = useBuilderStore.getState().builderLoading;
      if (currentLoading !== isLoading) {
        useBuilderStore.getState().update({ builderLoading: isLoading });
      }
    };
    await setIsLoading(true);
    try {
      await fn(...args);
    } finally {
      setIsLoading(false);
    }
  }) as unknown as T;
};

export type BuilderState = {
  addRecipeToNode: (
    recipe: RecipeDetailsFragment,
    nodeId: number,
    isPrecondition?: boolean,
    selectAfter?: boolean,
    addToTop?: boolean
  ) => Promise<void>;
  builderLoading: boolean;
  builderSaving: boolean;
  createNewRecipe: (
    name: string,
    id: string,
    description?: string
  ) => BuilderRecipe;
  currentRecipe: BuilderRecipe;
  currentRecipeDirtyState: BuilderRecipe;
  currentRecipeToYaml: (createYamlOptions?: CreateYamlOptions) => string;
  customRecipes: BuilderRecipe[];
  debug: boolean;
  deleteCustomRecipe: (id: string) => Promise<void>;
  deletePendingCustomRecipeId: string;
  fetchCustomRecipes: () => Promise<void>;
  hasTopologyAsMainContent: boolean;
  isEditable: boolean;
  isReplay: boolean;
  loadLastCheckpoint: () => Promise<void>;
  loadCustomRecipe: (id: string) => Promise<void>;
  loadCustomRecipeFromPersistedState: () => Promise<void>;
  loadRecipeFromData: (
    name: string,
    id: string,
    nodes: GraphNodeData[],
    links: GraphLink[],
    forceSave?: boolean
  ) => Promise<void>;
  loadRecipeFromDataTemporarily: (
    name: string,
    id: string,
    nodes: GraphNodeData[],
    links: GraphLink[]
  ) => Promise<void>;
  recipeId: string;
  removeRecipeNode: (nodeId: number) => Promise<void>;
  reset: () => void;
  resetTreeExpandedItems: () => void;
  rightSideBarWidth: string;
  router: ReturnType<typeof useRouter>;
  saveCustomRecipe: (
    recipe: BuilderRecipe,
    forceSave?: boolean,
    createCheckpoint?: boolean
  ) => Promise<void>;
  /** For deleting an entire recipe */
  showDeleteCustomRecipeDialog: boolean;
  /** For deleting a recipe node */
  showDeleteRecipeDialog: boolean;
  showEditCurrentRecipeAsYamlDialog: boolean;
  showEditNestedRecipeDialog: boolean;
  showEditRecipeDialog: boolean;
  showEditRecipeOptionsDialog: boolean;
  showEditYamlDialog: boolean;
  showImportFromOldBuilderDialog: boolean;
  showLoadCustomRecipePanel: boolean;
  showManageCustomRecipePanel: boolean;
  showNewRecipeDialog: boolean;
  showOverrideRecipeDialog: boolean;
  showPreconditionFinderPanel: boolean;
  showRecipeFinderPanel: boolean;
  startNewRecipe: () => Promise<void>;
  toggleMainContent: () => void;
  treeCollapseAll: () => void;
  treeExpandAll: () => void;
  treeExpandedItems: string[];
  updateCurrentRecipeDetails: (
    name: string,
    id: string,
    description: string,
    nodeId?: number
  ) => Promise<void>;
  updateSelectedRecipeOptions: (
    options: RecipeDetailsFragment['options']
  ) => void;
};

const builderDialogDefaults = {
  showDeleteCustomRecipeDialog: false,
  showDeleteRecipeDialog: false,
  showEditCurrentRecipeAsYamlDialog: false,
  showEditNestedRecipeDialog: false,
  showEditRecipeDialog: false,
  showEditRecipeOptionsDialog: false,
  showEditYamlDialog: false,
  showImportFromOldBuilderDialog: false,
  showLoadCustomRecipePanel: false,
  showManageCustomRecipePanel: false,
  showNewRecipeDialog: false,
  showOverrideRecipeDialog: false,
  showPreconditionFinderPanel: false,
  showRecipeFinderPanel: false
};

const builderStateDefaults: NonFunctionProperties<BuilderState> = {
  builderLoading: false,
  builderSaving: false,
  currentRecipe: null,
  currentRecipeDirtyState: null,
  customRecipes: [],
  debug: false,
  deletePendingCustomRecipeId: null,
  hasTopologyAsMainContent: true,
  isEditable: true,
  isReplay: false,
  recipeId: null,
  router: null,
  treeExpandedItems: ['0'],
  rightSideBarWidth: BUILDER_DEFAULT_RIGHT_SIDEBAR_WIDTH,
  ...builderDialogDefaults
};

export const useBuilderStore = createTemporalStore<BuilderState>(
  (set, get, store) => {
    store.temporal.getState().clear();
    store.temporal.getState().pause();

    const setWithCheckpoint = (
      currentState: BuilderState | Partial<BuilderState>
    ) => {
      store.temporal.getState().resume();
      set((previousState) => {
        const newState = {
          ...previousState,
          ...builderDialogDefaults,
          ...currentState
        };
        return newState;
      });
      store.temporal.getState().pause();
    };

    return {
      ...builderStateDefaults,
      loadLastCheckpoint: withLoading(async () => {
        store.temporal.getState().undo();
        const historicalState = get().currentRecipe;
        await get().saveCustomRecipe(historicalState, true, false);
      }),
      addRecipeToNode: withLoading(
        async (
          recipe,
          nodeId,
          isPrecondition,
          selectAfter = true,
          addToTop = false
        ) => {
          const { newRecipeState, nextAvailableId } =
            addRecipeDetailsFragmentToNode(
              get().currentRecipe,
              recipe,
              nodeId,
              isPrecondition,
              addToTop
            );

          await get().saveCustomRecipe(
            makeParentsCustomRecipe(nextAvailableId, newRecipeState)
          );

          if (selectAfter) {
            await useRecipeTopologyStore
              .getState()
              .updateSelectedRecipe(nextAvailableId);
          }
        }
      ),
      currentRecipeToYaml: (createYamlOptions = {}) => {
        const currentRecipe = get().currentRecipe;
        if (!currentRecipe) {
          return '';
        }
        const { nodes, links } = currentRecipe;
        return createYamlFromNodesAndLinks(nodes, links, createYamlOptions);
      },
      deleteCustomRecipe: async (id: string) => {
        if (typeof id !== 'string') {
          return;
        }
        const isInDb = await db?.builderRecipes?.get(id);
        if (isInDb) {
          await db?.builderRecipes?.delete(id);
        }
        get().fetchCustomRecipes();
        if (get().currentRecipe?.id === id) {
          set((state) => ({ ...state, currentRecipe: null }));
          useRecipeTopologyStore.getState().reset();
        }
      },
      fetchCustomRecipes: async () => {
        const customRecipes = (await db?.builderRecipes?.toArray()) || [];
        set({ customRecipes });
      },
      loadCustomRecipe: withLoading(async (id: string) => {
        if (!id) {
          return;
        }

        if (id !== get().currentRecipe?.id) {
          store.temporal.getState().clear();
        }

        const recipe = await db?.builderRecipes?.get(id);
        // update localStorage and sessionStorage with the recipe id
        sessionStorage.setItem('customRecipeId', id);
        localStorage.setItem('customRecipeId', id);
        const newRecipe = { ...recipe, links: cleanLinks(recipe?.links) };

        set((state) => ({
          ...state,
          currentRecipe: newRecipe
        }));
      }),
      loadCustomRecipeFromPersistedState: withLoading(async () => {
        const idFromSessionStorage = sessionStorage.getItem('customRecipeId');
        const idFromLocalStorage = localStorage.getItem('customRecipeId');
        const id = idFromSessionStorage || idFromLocalStorage;
        if (id) {
          await get().loadCustomRecipe(id);
        }
      }),
      loadRecipeFromDataTemporarily: withLoading(
        async (name, id, nodes, links) => {
          set((state) => ({
            ...state,
            currentRecipe: {
              name,
              id,
              description: nodes.find((n) => n.id === ROOT_NODE_ID)?.val
                ?.description,
              nodes,
              links
            }
          }));
          await useRecipeTopologyStore
            .getState()
            .updateSelectedRecipe(ROOT_NODE_ID);
        }
      ),
      loadRecipeFromData: withLoading(
        async (name, id, nodes, links, forceSave = false) => {
          const newRecipe = {
            name,
            id,
            nodes,
            links
          };
          await get().saveCustomRecipe(newRecipe, forceSave);
          await useRecipeTopologyStore
            .getState()
            .updateSelectedRecipe(ROOT_NODE_ID);
        }
      ),
      createNewRecipe: (name, id, description) => {
        return {
          name,
          id,
          description,
          nodes: [
            {
              isCustomRecipe: true,
              id: ROOT_NODE_ID,
              name,
              val: {
                name,
                id,
                tags: [],
                totalRecipes: 0,
                options: []
              }
            }
          ],
          links: []
        };
      },
      startNewRecipe: async () => {
        const newRecipe = get().createNewRecipe('', '', '');
        await set((state) => ({
          ...state,
          currentRecipe: newRecipe
        }));
        await useRecipeTopologyStore
          .getState()
          .updateSelectedRecipe(ROOT_NODE_ID);
        set({ showNewRecipeDialog: true });
      },
      removeRecipeNode: withLoading(removeNode),
      reset: () => {
        const {
          currentRecipe: _, // omit will cause infinite rerender loop
          customRecipes: __, // omit after reading from db these don't change unless we are intentional
          builderLoading: ___, // omit, we don't want reset controlling this
          builderSaving: ____, // omit, we don't want reset controlling this
          router: _____,
          ...resetables
        } = builderStateDefaults;
        set((state) => ({ ...state, ...resetables }));
      },
      resetTreeExpandedItems: () => {
        set({ treeExpandedItems: ['0'] });
      },
      saveCustomRecipe: async (
        recipe,
        forceSave = true,
        createCheckpoint = true
      ) => {
        if (get().isReplay && !get().showOverrideRecipeDialog) {
          forceSave = false;
          await set({ isReplay: false });
        }
        await set((state) => ({
          ...state,
          currentRecipeDirtyState: recipe,
          builderSaving: true,
          showOverrideRecipeDialog: false
        }));

        if (!recipe?.id) {
          return;
        }

        if (forceSave) {
          const isInDb = await db?.builderRecipes?.get(recipe.id);
          if (isInDb) {
            await db?.builderRecipes?.delete(recipe.id);
          }
        } else {
          const shouldDeferToPrompt = await promptForRecipeOverride(recipe.id);
          if (shouldDeferToPrompt) {
            return await set({ builderSaving: false });
          }
        }

        const newRecipe = { ...recipe, links: cleanLinks(recipe?.links) };
        await db?.builderRecipes?.put(newRecipe);
        await get().fetchCustomRecipes();

        if (createCheckpoint) {
          setWithCheckpoint({
            currentRecipe: newRecipe,
            currentRecipeDirtyState: null,
            builderSaving: false,
            builderLoading: false
          });
        } else {
          set((state) => ({
            ...state,
            currentRecipe: newRecipe,
            currentRecipeDirtyState: null,
            builderSaving: false,
            builderLoading: false
          }));
        }

        if (get().router) {
          await get().router.push('/builder');
        }
      },
      treeExpandAll: () => {
        set({
          treeExpandedItems: get().currentRecipe?.nodes.map((n) => String(n.id))
        });
      },
      treeCollapseAll: () => {
        set({ treeExpandedItems: [] });
      },
      updateCurrentRecipeDetails: withLoading(updateCurrentRecipeDetails),
      updateSelectedRecipeOptions: withLoading(updateSelectedNodeOptions),

      toggleMainContent: () => {
        useUserPreferenceStore.getState().update({
          builderLayout: !get().hasTopologyAsMainContent ? '3D' : '2D'
        });
        set({ hasTopologyAsMainContent: !get().hasTopologyAsMainContent });
      }
    };
  },
  ['currentRecipe']
);
