import { Box, SxProps, Theme, useTheme } from '@mui/material';
import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';

import { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import dynamic from 'next/dynamic';
import { Link } from '../moderne-link/moderne-link.component';
import { MarkdownErrorBoundary } from './markdown.error-boundary';
const DynamicMermaid = dynamic(
  () => import('../render-mermaid/render-mermaid.component'),
  { ssr: false }
);

type ModerneMarkdownProps = {
  allowHtml?: boolean;
  children: string;
  disableCodeFontColor?: boolean;
  overrides?: MarkdownToJSX.Overrides;
  variant?: 'underline' | 'contained';
};

/**
 * Uses `markdown-to-jsx` to process `children`
 * Applies custom color for `code` syntax
 */
export const ModerneMarkdown: FunctionComponentWithChildren<
  ModerneMarkdownProps
> = ({
  allowHtml = false,
  children,
  disableCodeFontColor = false,
  overrides,
  variant = 'underline'
}) => {
  const theme = useTheme();
  if (!children) {
    return null;
  }

  const styles = {
    underline: {
      color: disableCodeFontColor ? 'inherit' : theme.palette.grey[700],
      borderBottom: `1px dotted ${theme.palette.grey[300]}`,
      fontSize: 'inherit'
    },
    contained: {
      backgroundColor: theme.palette.grey[150],
      borderRadius: 1,
      padding: '2px 4px',
      color: theme.palette.grey[700],
      fontSize: 'inherit'
    }
  };

  const mergedOverrides = {
    a: {
      component: Link,
      props: {
        external: true
      }
    },
    code: {
      component: MarkdownCode,
      props: {
        sx: styles[variant]
      }
    },
    ...overrides
  };

  const preprocessedChildren = children
    /**
     * In markdown a \ at the end of a line is a linebreak
     */
    .replace(/\s\\\n/g, '\n\n')
    /**
     * Replace new lines with two new lines so the rendered markdown has
     * proper linebreaks. There are multiple issues with this Markdown
     * package regarding linebreaks
     *
     *     @see https://github.com/probablyup/markdown-to-jsx/pull/291
     */
    .replace('\n', '\n\n');

  return (
    <MarkdownErrorBoundary>
      <Markdown
        options={{
          disableParsingRawHTML: !allowHtml,
          overrides: mergedOverrides
        }}>
        {preprocessedChildren}
      </Markdown>
    </MarkdownErrorBoundary>
  );
};

type MarkdownCodeProps = {
  sx?: SxProps<Theme>;
  className?: string;
};

const MarkdownCode: FunctionComponentWithChildren<MarkdownCodeProps> = ({
  className,
  children,
  sx
}) => {
  const language = className?.replace('lang-', '');

  if (language === 'mermaid') {
    return (
      <Box>
        <DynamicMermaid>{children}</DynamicMermaid>
      </Box>
    );
  }

  return (
    <Box component="code" sx={sx}>
      {children}
    </Box>
  );
};
