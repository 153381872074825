import { PureFunction } from '@bonsai-components/utility-types';
import { RecipeDetailsFragment } from '../__generated__/apollo-hooks';
import {
  MaybeRecipeList,
  RecipeWithOptions
} from '../stores/recipe-builder.store';
import { isValidOptionValue } from './recipe.helper';

export const upsertRecipe: PureFunction<
  {
    recipeList: MaybeRecipeList;
    recipe: RecipeDetailsFragment | RecipeDetailsFragment[];
    position?: number;
  },
  MaybeRecipeList
> = ({ recipeList, recipe, position }) => {
  let newRecipeList;
  if (Array.isArray(recipe)) {
    // reduce recipe down to an array of recipes that only have id,name, and options
    const reducedRecipe = recipe.map(({ id, name, options }) => ({
      id,
      name,
      options
    }));
    newRecipeList = [...recipeList, ...reducedRecipe];
  } else {
    newRecipeList = [...recipeList];
    newRecipeList.splice(
      position !== undefined ? position : newRecipeList.length,
      0,
      { id: recipe.id, name: recipe.name, options: recipe.options }
    );
  }
  return newRecipeList;
};

export const mapRecipeDetailsFragmentToDisplay: PureFunction<
  RecipeWithOptions,
  string | Record<string, Record<string, string | number | boolean | string[]>>
> = (recipe) => {
  const hasOpts = recipe?.options?.length > 0;
  if (hasOpts) {
    const validOptions = Object.fromEntries(
      recipe.options
        .filter(isValidOptionValue)
        .map(({ name, value }) => [name, value])
    );
    if (Object.keys(validOptions)?.length === 0) {
      return recipe?.id;
    } else {
      return {
        [recipe.id]: validOptions
      };
    }
  } else {
    return recipe?.id;
  }
};
