import { PureFunction } from '@bonsai-components/utility-types';
import { useCallback, useEffect, useState } from 'react';
import { GraphQLOperations } from '../__generated__/apollo-helpers';
import {
  CreateUserOrganizationMutationResult,
  CreateUserOrganizationMutationVariables,
  OrganizationsByIdQuery,
  RepositoryFragment,
  RepositoryInput,
  SimpleOrganizationByIdQuery,
  UpdateUserOrganizationMutationResult,
  UpdateUserOrganizationMutationVariables,
  useCreateUserOrganizationMutation,
  useDefaultOrganizationLazyQuery,
  useOrganizationsByIdLazyQuery,
  useSimpleOrganizationByIdLazyQuery,
  useUpdateUserOrganizationMutation
} from '../__generated__/apollo-hooks';

import { ApolloError } from '@apollo/client';
import { mapRepositoryFragmentToId } from '../helpers/repository.helpers';
import { useSelectedOrganizationStore } from '../stores/organization-store';

const getNodeWithId = ({ node }) => ({
  ...node,
  id: mapRepositoryFragmentToId(node)
});

export const useGetAllOrganizationRepositories = (
  id?: string
): {
  loading: boolean;
  data: OrganizationsByIdQuery;
  getOrganizationRepositories: (params: {
    id: string;
  }) => Promise<RepositoryFragment[]>;
} => {
  const [loading, setLoading] = useState(false);
  const [fetchOrganizationRepository, { fetchMore, data }] =
    useOrganizationsByIdLazyQuery();

  const getOrganizationRepositories = async ({ id }: { id: string }) => {
    setLoading(true);
    const response = await fetchOrganizationRepository({
      variables: {
        id,
        filter: {
          showMissing: true,
          showOrphaned: true
        }
      }
    });

    let hasNextPage =
      response?.data?.organization?.repositories?.pageInfo?.hasNextPage;
    let afterCursor =
      response?.data?.organization?.repositories?.pageInfo?.endCursor;

    let nodes =
      response?.data?.organization?.repositories?.edges?.map(getNodeWithId);

    while (hasNextPage) {
      const nextPageNodes = await fetchMore({
        variables: {
          after: afterCursor
        }
      });

      hasNextPage =
        nextPageNodes?.data?.organization?.repositories?.pageInfo?.hasNextPage;
      afterCursor =
        nextPageNodes?.data?.organization?.repositories?.pageInfo?.endCursor;
      const newNodes =
        nextPageNodes?.data?.organization?.repositories?.edges?.map(
          getNodeWithId
        );
      nodes = [
        ...nodes,
        ...newNodes.filter((node) => !nodes?.some((d) => d.id === node.id))
      ];
    }

    setLoading(false);
    return nodes;
  };

  const memoizedGetOrganizationRepositories = useCallback(
    getOrganizationRepositories,
    [fetchMore, fetchOrganizationRepository]
  );

  useEffect(() => {
    if (id) {
      memoizedGetOrganizationRepositories({ id });
    }
  }, [memoizedGetOrganizationRepositories, id]);

  return { loading, data, getOrganizationRepositories };
};

type UpsertUserOrganizationParams = {
  id?: string;
  name: string;
  description: string;
  repositoryInputs: RepositoryInput[];
};

export type UpsertUserOrganizationResult =
  | CreateUserOrganizationMutationResult['data']['createUserOrganization']
  | UpdateUserOrganizationMutationResult['data']['updateUserOrganization'];

export const useUpsertUserOrganization: PureFunction<
  void,
  (
    params: UpsertUserOrganizationParams
  ) => Promise<UpsertUserOrganizationResult>
> = () => {
  const [createUserOrganization] = useCreateUserOrganizationMutation();
  const [updateUserOrganization] = useUpdateUserOrganizationMutation();

  return async ({
    id,
    name,
    description,
    repositoryInputs
  }: UpsertUserOrganizationParams) => {
    if (id) {
      const userOrganizationInput: UpdateUserOrganizationMutationVariables['userOrganization'] =
        {
          id,
          name: name,
          repositories: repositoryInputs,
          description: description
        };

      return (
        await updateUserOrganization({
          variables: { userOrganization: userOrganizationInput },
          refetchQueries: [GraphQLOperations.Query.organizationSelectorGrid],
          awaitRefetchQueries: true
        })
      ).data?.updateUserOrganization;
    } else {
      const userOrganizationInput: CreateUserOrganizationMutationVariables['userOrganization'] =
        {
          name: name,
          repositories: repositoryInputs,
          description: description
        };
      return (
        await createUserOrganization({
          variables: {
            userOrganization: userOrganizationInput
          },
          refetchQueries: [GraphQLOperations.Query.organizationSelectorGrid],
          awaitRefetchQueries: true
        })
      ).data?.createUserOrganization;
    }
  };
};

type UseSelectedOrganizationHookResult = {
  selectedOrganization: SimpleOrganizationByIdQuery['organization'];
  loading: boolean;
  error?: ApolloError;
};

/**
 * A custom hook that manages the selection of an organization.
 *
 * This hook retrieves the currently selected organization based on the
 * `selectedOrganizationId` from the organization store. If no organization
 * is selected, it fetches the default organization. It utilizes two lazy
 * queries: one for fetching an organization by its ID and another for
 * fetching the default organization. The hook ensures that the appropriate
 * organization data is fetched only once when the component mounts.
 *
 * The hook returns the selected organization data, loading state, and
 * any errors encountered during the fetching process.
 */
export const useSelectedOrganization =
  (): UseSelectedOrganizationHookResult => {
    const { selectedOrganizationId, update } = useSelectedOrganizationStore();

    const [getOrganizationById, { data, loading, error }] =
      useSimpleOrganizationByIdLazyQuery({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        context: {
          operationName: 'useSelectedOrganizationHook:ID'
        }
      });

    const [
      getDefaultOrganization,
      {
        data: defaultOrganizationData,
        loading: defaultOrganizationLoading,
        error: defaultOrganizationError
      }
    ] = useDefaultOrganizationLazyQuery({
      // we do not want to update the cache as we just watch to fetch so we can
      // immediately update the selected organization. this would trigger a new
      // call to getOrganizationById
      fetchPolicy: 'no-cache',
      context: {
        operationName: 'useSelectedOrganizationHook:Default'
      },
      onCompleted: (data) => {
        update({
          selectedOrganizationId: data?.organizationsPages?.edges.at(0).node.id
        });
      }
    });

    // Only fire the effect once
    useEffect(() => {
      const fetchSelectedOrDefaultOrganization = async () => {
        if (selectedOrganizationId) {
          await getOrganizationById({
            variables: {
              id: selectedOrganizationId
            }
          });
        } else {
          await getDefaultOrganization();
        }
      };

      fetchSelectedOrDefaultOrganization();
    }, [getDefaultOrganization, getOrganizationById, selectedOrganizationId]);

    return {
      selectedOrganization:
        data?.organization ||
        defaultOrganizationData?.organizationsPages?.edges.at(0).node,
      loading: loading || defaultOrganizationLoading,
      error: error || defaultOrganizationError
    };
  };
