import type { Session } from 'next-auth';

/**
 * Resolves avatar source url
 *
 * The "User Attribute" session.user.picture is either hardcoded in keycloak for
 * the particular user or the identity provider's mapper defined in keycloak
 * will dynamically set it when the user logs in based on the user fields
 * provided by the identity provider.
 */
export const getAvatarSource = (session: Session): string | undefined => {
  return session?.user?.picture;
};
