import { FunctionComponent, ReactElement } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Tooltip } from '@mui/material';

type CopyToClipBoardWithTooltipProps = {
  children: ReactElement;
  copied: boolean;
  onCopy: () => void;
  text: string;
  tooltip: string;
};

export const CopyToClipboardWithTooltip: FunctionComponent<
  CopyToClipBoardWithTooltipProps
> = ({ onCopy, children, copied, tooltip, text }) => (
  <CopyToClipboard text={text} onCopy={onCopy}>
    <Tooltip title={copied ? 'Copied!' : tooltip}>{children}</Tooltip>
  </CopyToClipboard>
);
