import { GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { LOCAL_STORAGE_USER_PREFERENCES } from '../constants/general';
import { getObjectFromLocalStorage } from '../helpers/local-storage.helper';
import { createPersistedStore } from './store-creators';

type UserPreferenceState = {
  showAllResults: boolean;
  builderLayout: '2D' | '3D';
  disableKeyboardShortcuts: boolean;
  recipeResultColumnVisibility: GridColumnVisibilityModel;
  dismissedAgentUpdates: string[];
  previousSearchQueries: string[];
};

const initialization = (obj) => {
  if (!globalThis?.window?.localStorage) {
    return obj;
  }
  const old = getObjectFromLocalStorage<UserPreferenceState>(
    LOCAL_STORAGE_USER_PREFERENCES
  );

  if (!old) {
    return obj;
  }
  const {
    showAllResults,
    disableKeyboardShortcuts,
    recipeResultColumnVisibility,
    dismissedAgentUpdates,
    previousSearchQueries
  } = old;
  delete old.showAllResults;
  delete old.disableKeyboardShortcuts;
  delete old.recipeResultColumnVisibility;
  delete old.dismissedAgentUpdates;
  delete old.previousSearchQueries;

  localStorage.setItem(LOCAL_STORAGE_USER_PREFERENCES, JSON.stringify(old));
  return {
    ...obj,
    showAllResults,
    disableKeyboardShortcuts,
    recipeResultColumnVisibility,
    dismissedAgentUpdates,
    previousSearchQueries
  };
};

export const useUserPreferenceStore = createPersistedStore<UserPreferenceState>(
  () => ({
    builderLayout: '3D',
    showAllResults: true,
    disableKeyboardShortcuts: false,
    previousSearchQueries: [],
    recipeResultColumnVisibility: {
      worker: false,
      astLoading: false,
      infoMarkers: false,
      warningMarkers: false,
      errorMarkers: false,
      debugMarkers: false,
      details: false,
      recipeRun: false,
      dependencyResolution: false,
      lastUpdated: false
    },
    dismissedAgentUpdates: []
  }),
  {
    name: 'user-preference'
  },
  initialization
);
