import React, { ReactElement } from 'react';

import { Menu, MenuProps as MUIMenuProps } from '@mui/material';

import { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import {
  bindPopover,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import { usePopoverStore } from '../../../../stores/popover-store';

export const MenuBase: FunctionComponentWithChildren<{
  menuId: string;
  actionItem: ReactElement;
  MenuProps?: Partial<MUIMenuProps>;
}> = ({ menuId, actionItem, children, MenuProps = undefined }) => {
  const popoverState = usePopupState({ variant: 'popover', popupId: menuId });
  const { update } = usePopoverStore();

  React.useEffect(() => {
    if (popoverState.isOpen) {
      update({ popover: popoverState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popoverState.isOpen]);

  return (
    <React.Fragment>
      {React.cloneElement(actionItem, {
        ...bindTrigger(popoverState),
        'aria-controls': popoverState.isOpen ? 'basic-menu' : undefined,
        'aria-haspopup': true,
        'aria-expanded': popoverState.isOpen ? 'true' : undefined
      })}

      <Menu
        id={menuId}
        keepMounted
        data-testid={menuId}
        {...bindPopover(popoverState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        {...MenuProps}>
        {children}
      </Menu>
    </React.Fragment>
  );
};
