import { styled, Typography, TypographyProps } from '@mui/material';
import { textColor } from '../../../themes/moderne-theme';

export const ClampedTypography = styled(Typography)(() => ({
  overflow: 'hidden',
  overflowWrap: 'anywhere',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  fontSize: 'inherit',
  fontWeight: 'inherit'
}));

export const EmphasizedFilePath = styled('b')(({ theme }) => ({
  whiteSpace: 'nowrap',
  fontFamily: theme.typography.fontFamilyMonospace
}));

export const KeyStroke = styled('kbd')(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(0.2, 0.8),
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(0.25),
  color: textColor('high')(theme)
}));

export const Preformatted = styled('pre')(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyMonospace,
  margin: 0
}));

export const PreformattedWithBreak = styled(Preformatted)(() => ({
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap'
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InlinePre = styled(({ ref, ...props }: TypographyProps) => (
  <Typography component="pre" noWrap {...props} />
))(({ theme }) => ({
  display: 'inline',
  fontFamily: theme.typography.fontFamilyMonospace
}));
