import { styled } from '@mui/material';
import { OpposingBoxes } from '../styled-components/layouts/layouts.styled';

export const DownloadableRow = styled(OpposingBoxes)(({ theme }) => ({
  padding: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  height: '100%',
  alignItems: 'center',
  backgroundColor: theme.palette.grey[125]
}));
