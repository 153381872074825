import kebabcase from 'lodash.kebabcase';
import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material';
import { determineHref } from '../../../helpers/link.helper';
import { Link } from '../../utilities/moderne-link/moderne-link.component';
import { LeftNavMenuProps } from './navigation-items';

type LeftNavigationMenuItemProps = {
  menuItem: LeftNavMenuProps;
  onClick?: () => void;
};
export const LeftNavigationMenuItem: FunctionComponent<
  LeftNavigationMenuItemProps
> = ({ menuItem, onClick }) => {
  const { asPath } = useRouter();
  const selectedMenuItemRegex = new RegExp(`^${menuItem.href}`, 'i');
  return (
    <Tooltip title={menuItem.description} placement="right" arrow>
      <ListItem>
        <ListItemButton
          data-testid={`global-nav-${kebabcase(menuItem.label)}-button`}
          selected={selectedMenuItemRegex.test(asPath)}
          href={determineHref(menuItem.href)}
          onClick={onClick}
          LinkComponent={Link}>
          <ListItemIcon>{menuItem.icon}</ListItemIcon>
          <ListItemText primary={menuItem.label} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};
