import { FormControlLabel, Switch } from '@mui/material';
import { FunctionComponent } from 'react';
import { useUiFeaturesStore } from '../../stores/ui-features.store';

export const AiSearchFeatureControl: FunctionComponent = () => {
  const { useAiSearch, update } = useUiFeaturesStore();
  const handleOnChange = (e) => {
    update({ useAiSearch: e.target.checked });
  };
  return (
    <FormControlLabel
      onChange={handleOnChange}
      checked={useAiSearch}
      control={<Switch size="small" />}
      label="AI assisted search"
      labelPlacement="start"
    />
  );
};
