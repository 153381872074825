import { Group } from '@tweenjs/tween.js';
import React from 'react';
import * as THREE from 'three';
import { RecipeDetailsFragment } from '../__generated__/apollo-hooks';
import { findDescendantNodeIds } from '../helpers/builder/builder-links.helpers';
import { createStore } from './store-creators';

export const ROOT_NODE_ID = 0;

export const TITLE_FONT_SIZE = [10, 10, 10, 12];

export type GraphNodeData = {
  /**
   * This is not the recipe ID.This is a node Id which is more of an index
   * assigned when we recurse through the list of recipes
   */
  id: number;
  name: string;
  val?: RecipeDetailsFragment;
  isCustomRecipe?: boolean;
  isPrecondition?: boolean;
};

export type GraphLink = {
  /**
   * references a `GraphNodeData.id`
   */
  source: number;
  /**
   * references a `GraphNodeData.id`
   */
  target: number;
};

const defaultColors = {
  ORB_SHADER_DARK_COLOR: '#7686d6',
  ORB_SHADER_LIGHT_COLOR: '#d6c5f7',
  ORB_SHADER_DARK_COLOR__SELECTED: '#3164c9',
  ORB_SHADER_LIGHT_COLOR__SELECTED: '#2E42FF',
  ORB_SHADER_DARK_COLOR__HOVERED: '#3d7b6f',
  ORB_SHADER_LIGHT_COLOR__HOVERED: '#56dcb4',
  ORB_SHADER_DARK_COLOR__MATCHED: '#47b19c',
  ORB_SHADER_LIGHT_COLOR__MATCHED: '#bee9dc',
  SELECTED_GLOW_COLOR: '#e9ea95',
  BADGE_PRECONDITION_COLOR: '#F8ABD6',
  BADGE_OPTIONS_COLOR: '#E0E0E0',
  ORB_SHADER_DARK_COLOR__PRECONDITION: '#DB4197',
  ORB_SHADER_LIGHT_COLOR__PRECONDITION: '#ffc2f2',
  LINK_COLOR: '#74765b',
  SELECTED_LINK_COLOR: '#837620',
  BACKGROUND_COLOR: '#31323f'
};

export type RecipeTopologyStoreState = {
  /**
   * Uses ref.
   * Enables being able to use orbital controls from outside of the Canvas
   * Ex: Floating React control for Recipe explorer which is outside of the
   * `Canvas` but will still interact with the `OrbitCamera` within the `Canvas`
   */
  cameraControlsRef: typeof cameraControlsRef;
  tweenGroup: Group;
  colors: { [k in keyof typeof defaultColors]: (typeof defaultColors)[k] };
  customCameraFunctions: {
    zoomToNode: (id: number) => void;
    zoomToFit: () => void;
  };
  debug: boolean;
  links: GraphLink[];

  /**
   * uses refs because we don't want the React renderer to do anything when updating
   */
  nodePositions: typeof nodePositions;
  nodes: GraphNodeData[];
  recipeCount: number;
  preconditionCount: number;
  reset: () => void;
  searchTerm: string;
  selectedRecipeNode: GraphNodeData;
  descendantNodeIds: number[];
  showSelectedNodeFloatingPane: boolean;
  showLegend: boolean;
  updateRootRecipe: (nodes: GraphNodeData[], links: GraphLink[]) => void;
  updateSelectedRecipe: (selectedRecipeNodeId: number) => void;
};

const nodePositions = React.createRef<
  Record<number, THREE.Vector3>
>() as React.MutableRefObject<Record<number, THREE.Vector3>>;
nodePositions.current = {};

const cameraControlsRef =
  React.createRef<THREE.CameraControls>() as React.MutableRefObject<THREE.CameraControls>;

export const useRecipeTopologyStore = createStore<RecipeTopologyStoreState>(
  (set, get) => ({
    cameraControlsRef,
    colors: defaultColors,
    nodePositions,
    customCameraFunctions: null,
    debug: false,
    links: [],
    tweenGroup: new Group(),
    nodes: [],
    recipeCount: 0,
    preconditionCount: 0,
    reset: () => {
      set((state) => ({
        ...state,
        debug: false,
        colors: defaultColors,
        searchTerm: '',
        selectedRecipeNode: null,

        nodes: [],
        links: [],
        customCameraFunctions: null,
        showSelectedNodeFloatingPane: true
      }));
    },
    searchTerm: '',
    selectedRecipeNode: null,
    descendantNodeIds: [],
    showSelectedNodeFloatingPane: true,
    showLegend: false,
    updateRootRecipe: (nodes, links) => {
      set((state) => ({ ...state, nodes, links }));

      get().updateSelectedRecipe(ROOT_NODE_ID);
    },
    updateSelectedRecipe: (selectedRecipeNodeId) => {
      let selectedRecipeNode;
      let recipeCount = 0;
      let preconditionCount = 0;
      let preconditionChildNodeIds = [];

      get().nodes.forEach((node) => {
        if (node.id === selectedRecipeNodeId) {
          selectedRecipeNode = node;
        }

        if (node.isPrecondition) {
          preconditionCount += 1;
          preconditionChildNodeIds = [
            ...new Set([
              ...preconditionChildNodeIds,
              ...findDescendantNodeIds(node.id, get().links)
            ])
          ];
        } else {
          if (preconditionChildNodeIds.includes(node.id)) {
            preconditionCount += 1;
          } else {
            // exclude the root node from the list count
            if (ROOT_NODE_ID !== node.id) {
              recipeCount += 1;
            }
          }
        }
      });

      const descendantNodeIds =
        selectedRecipeNode?.id === ROOT_NODE_ID
          ? get().nodes.reduce((result, node) => [...result, node.id], [])
          : findDescendantNodeIds(selectedRecipeNodeId, get().links);

      set((state) => ({
        ...state,
        selectedRecipeNode,
        descendantNodeIds,
        recipeCount,
        preconditionCount
      }));
    }
  })
);
