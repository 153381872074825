import { Box, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import React, { useState } from 'react';
import { GraphQLOperations } from '../__generated__/apollo-helpers';
import {
  useDeleteUserOrganizationMutation,
  useSimpleOrganizationByIdLazyQuery
} from '../__generated__/apollo-hooks';
import { OrganizationRowData } from '../components/organizations/organizations.rows';
import { UserOrganizationDialog } from '../components/organizations/user-organization-editor/user-organization-dialog.component';
import { downloadStringAsFile } from '../helpers/download.helper';
import { minimalRepositoryReducer } from '../helpers/repository.helpers';
import { OrganizationIcon } from '../icons/icons';
import { useSelectedOrganizationStore } from '../stores/organization-store';
import { useGetAllOrganizationRepositories } from './use-organizations.hooks';

export const useRemoveUserOrganization = () => {
  const [deleteOrganization] = useDeleteUserOrganizationMutation();
  const { selectedOrganizationId, update } = useSelectedOrganizationStore();
  const confirm = useConfirm();

  const removeOrganization = async (id: string) => {
    await deleteOrganization({
      variables: { id },
      refetchQueries: [GraphQLOperations.Query.organizationSelectorGrid],
      awaitRefetchQueries: true,
      onCompleted: () => {
        // only reset the selected organization if the deleted organization is
        // the selected one
        if (id === selectedOrganizationId) {
          update({ selectedOrganizationId: null });
        }
      }
    });
  };

  const handleConfirmRemoveOrganization = (orgRowData) => () =>
    confirm({
      title: 'Delete organization',
      description: (
        <React.Fragment>
          <Typography
            sx={{
              marginBottom: 2
            }}>
            Are you sure you want to delete this organization?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start'
            }}>
            <OrganizationIcon />
            <Box>
              <Typography
                variant="body2"
                sx={{
                  marginLeft: 2,
                  marginBottom: 1,
                  fontWeight: 700
                }}>
                {orgRowData?.name}
              </Typography>
            </Box>
          </Box>
        </React.Fragment>
      )
    }).then(() => removeOrganization(orgRowData.id));

  return handleConfirmRemoveOrganization;
};

export const useCreateOrEditUserOrganization = () => {
  const [selectedOrganizationRow, setSelectedOrganizationRow] =
    useState<OrganizationRowData>(null);
  const [isCopy, setIsCopy] = useState<boolean>(false);

  const resetSelectedOrganizationRow = () => setSelectedOrganizationRow(null);
  const copyUserOrganization = (orgRowData: OrganizationRowData) => {
    setIsCopy(true);
    setSelectedOrganizationRow(orgRowData);
  };

  const editUserOrganization = (orgRowData: OrganizationRowData) => {
    setIsCopy(false);
    setSelectedOrganizationRow(orgRowData);
  };

  const renderOrganizationModal = () =>
    selectedOrganizationRow && (
      <UserOrganizationDialog
        orgRowData={selectedOrganizationRow}
        open
        onClose={resetSelectedOrganizationRow}
        copy={isCopy}
      />
    );

  return {
    copyUserOrganization,
    editUserOrganization,
    renderOrganizationModal: renderOrganizationModal()
  };
};

export const useExportUserOrganization = () => {
  const [getOrganizationById] = useSimpleOrganizationByIdLazyQuery();
  const { getOrganizationRepositories } = useGetAllOrganizationRepositories();

  const exportUserOrganizations = async (id: string) => {
    const { data } = await getOrganizationById({
      variables: { id }
    });

    const { organization } = data;

    let repositories = [];
    if (organization?.isUserOrganization) {
      repositories = await getOrganizationRepositories({
        id
      });
    }

    const group = minimalRepositoryReducer({ ...organization, repositories });

    downloadStringAsFile(
      JSON.stringify(group, null, 2),
      `${organization.name}.json`
    );
  };
  return exportUserOrganizations;
};
