import { SEARCH_INPUT_ID } from '../constants/general';

const FOCUSABLE_SELECTOR =
  'a,frame,iframe,input:not([type=hidden]):not(:disabled),select:not(:disabled),textarea:not(:disabled),button:not(:disabled):not([tabindex="-1"]),*[tabindex]:not([tabindex="-1"])';

/**
 * Looks for a focusable within Element you provide and focuses on the
 * first focusable found.
 */
export const focusFirstFocusable = (element: Element): void => {
  const firstFocusable = element?.querySelector(FOCUSABLE_SELECTOR);
  (firstFocusable as HTMLElement)?.focus();
};

/**
 * Focuses the global search input
 */
export const focusGlobalSearchInput = (e: React.KeyboardEvent<Element>) => {
  e.preventDefault();
  (document.activeElement as HTMLElement)?.blur();
  document.getElementById(SEARCH_INPUT_ID)?.focus();
};
