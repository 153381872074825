import { GlobalKeysContext } from '@bonsai-components/react-global-keys';
import { useContext, useEffect } from 'react';

import {
  CommandPaletteContext,
  CommandPaletteItem,
  CommandWithKeyBinding
} from '../contexts/command-palette.context';

const EXCLUDED_SELECTOR = 'input, textarea, [role="textbox"],.CodeMirror-code';

export const excludeActionFromInputMode = (
  e: React.KeyboardEvent<Element>,
  action: (e: React.KeyboardEvent<Element>) => void | Promise<boolean>
) => {
  const excludedElements = Array.from(
    document.querySelectorAll(EXCLUDED_SELECTOR)
  );
  const isExcluded = excludedElements.includes(e.target as HTMLElement);
  if (isExcluded) {
    return;
  }
  return action(e);
};

/**
 * Composes addCommands from CommandPaletteContext and addKeyBinding from
 * GlobalKeysContext and returns a single function that can be called on a
 * command input or array of command inputs
 */
const useAddCommandsAndKeyBindings = () => {
  const { addCommands } = useContext(CommandPaletteContext);
  const { addKeyBinding } = useContext(GlobalKeysContext);

  return (command: CommandPaletteItem) => {
    addCommands({ ...command });
    if (command.keyboardShortcuts) {
      if (Array.isArray(command.keyboardShortcuts)) {
        command.keyboardShortcuts.forEach((kb) => {
          addKeyBinding({
            ...kb,
            action: (e) =>
              kb.includeInputMode
                ? command.action(e)
                : excludeActionFromInputMode(e, command.action),
            description: command.description || command.label
          });
        });
      } else {
        addKeyBinding({
          ...command.keyboardShortcuts,
          action: (e) =>
            (command.keyboardShortcuts as CommandWithKeyBinding)
              .includeInputMode
              ? command.action(e)
              : excludeActionFromInputMode(e, command.action),
          description: command.description || command.label
        });
      }
    }
  };
};

/**
 * Adds a command input or array of command inputs to the appropriate contexts
 * on mount.
 */
export const useCommandPalette = (
  commands: CommandPaletteItem | CommandPaletteItem[]
) => {
  const composedAddCommand = useAddCommandsAndKeyBindings();
  const { removeCommands } = useContext(CommandPaletteContext);

  useEffect(() => {
    if (Array.isArray(commands)) {
      commands.forEach(composedAddCommand);
    } else {
      composedAddCommand(commands);
    }
    // Remove commands on unmount to prevent page specific commands from leaking
    return () => {
      if (Array.isArray(commands)) {
        commands.forEach((command) => removeCommands(command.label));
      } else {
        removeCommands(commands.label);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
