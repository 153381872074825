import { PersistStorage, StorageValue } from 'zustand/middleware/persist';
import { SESSION_STORAGE_SELECTED_REPOSITORY_GROUP } from '../constants/general';
import { Store, createPersistedStore } from './store-creators';

const BROWSER_STORAGE_SELECTED_ORGANIZATION_ID =
  'selected-organization-id' as const;

type OrganizationStore = {
  selectedOrganizationId: string;
};

const storage: PersistStorage<Store<OrganizationStore>> = {
  getItem: (
    name: string
  ): StorageValue<Store<OrganizationStore>> | undefined => {
    return JSON.parse(
      window.sessionStorage.getItem(name) || window.localStorage.getItem(name)
    );
  },
  setItem: (name: string, value: StorageValue<Store<OrganizationStore>>) => {
    window.sessionStorage.setItem(name, JSON.stringify(value));
    window.localStorage.setItem(name, JSON.stringify(value));
  },
  removeItem: (name: string) => {
    window.sessionStorage.removeItem(name);
    window.localStorage.removeItem(name);
  }
};

const initialization = (obj) => {
  if (!globalThis?.window?.localStorage) {
    return obj;
  }

  const oldSelectedOrganization = window.localStorage.getItem(
    SESSION_STORAGE_SELECTED_REPOSITORY_GROUP
  );
  if (oldSelectedOrganization) {
    window.localStorage.setItem(
      BROWSER_STORAGE_SELECTED_ORGANIZATION_ID,
      oldSelectedOrganization
    );
    oldSelectedOrganization;
  } else {
    return obj;
  }
};

/**
 * A simple store for retrieving and updating the selected organization for
 * the current user. This store is persisted in both session and local storage.
 */
export const useSelectedOrganizationStore =
  createPersistedStore<OrganizationStore>(
    () => ({
      selectedOrganizationId: undefined
    }),
    {
      name: BROWSER_STORAGE_SELECTED_ORGANIZATION_ID,
      storage
    },
    initialization
  );
