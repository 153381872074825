import { useEditorContext } from '@graphiql/react';
import { MenuItem } from '@mui/material';
import { FunctionComponent, useContext } from 'react';
import { EXPLORE_API_PATH } from '../../constants/general';
import { NotificationContext } from '../../contexts/notification.context';
import { encodeJSONBase64, utf8ToB64 } from '../../helpers/encoding.helper';

export const ShareGraphiQL: FunctionComponent<{ url: string }> = ({ url }) => {
  const { queryEditor, variableEditor } = useEditorContext();
  const { renderNotification } = useContext(NotificationContext);

  const handleCopy = () => {
    const shareableUrl = new URL(window.location.origin);
    shareableUrl.pathname = EXPLORE_API_PATH;

    if (url) {
      shareableUrl.searchParams.append('url', encodeURIComponent(url));
    }

    if (queryEditor.getValue() !== '') {
      /**
       * Queries are not JSON so we just need a simple base64 encoding
       */
      shareableUrl.searchParams.append(
        'query',
        utf8ToB64(queryEditor.getValue())
      );
    }

    if (variableEditor.getValue() !== '') {
      /**
       * Variables are JSON so we need to encode them as JSON first
       */
      shareableUrl.searchParams.append(
        'variables',
        encodeJSONBase64(variableEditor.getValue())
      );
    }

    navigator.clipboard.writeText(shareableUrl.toString()).then(() => {
      renderNotification('success', 'Copied query and variables to clipboard');
    });
  };

  return <MenuItem onClick={handleCopy}>Share query</MenuItem>;
};
