import { useDebouncedCallback } from 'use-debounce';
import { DOHERTY_THRESHOLD } from '../constants/general';

/**
 * Creates a debounced function that delays invoking the callback function
 * until after input has stopped for the DOHERTY_THRESHOLD.
 *
 * For custom implementations, see use-debounce.
 */
export function useStandardDebouncedCallback<
  T extends (...args: unknown[]) => ReturnType<T>
>(func: T) {
  return useDebouncedCallback(func, DOHERTY_THRESHOLD, { trailing: true });
}
