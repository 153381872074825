import {
  RepositoryFragment,
  RepositoryInput
} from '../__generated__/apollo-hooks';
import { WorkerRepository } from '../__generated__/apollo-hooks-worker';

export const repositoryFragmentToInput = (
  repository: Partial<RepositoryFragment> | WorkerRepository
): RepositoryInput => ({
  branch: repository.branch,
  origin: repository.origin,
  path: repository.path
});
