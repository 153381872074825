import { FunctionComponent } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

import {
  RepositoryFragment,
  ScmConfiguration,
  isRepositoryAAzureDevOpsRepository,
  isRepositoryABitbucketCloudRepository,
  isRepositoryABitbucketRepository,
  isRepositoryAGitHubRepository,
  isRepositoryAGitLabRepository,
  isScmConfigurationAAzureDevOpsConfiguration,
  isScmConfigurationABitbucketCloudConfiguration,
  isScmConfigurationABitbucketConfiguration,
  isScmConfigurationAGitLabConfiguration,
  isScmConfigurationAGithubConfiguration
} from '../../../__generated__/apollo-hooks';
import { WorkerRepositoryFragment } from '../../../__generated__/apollo-hooks-worker';
import {
  AzureDevOpsIcons,
  BitBucketIcon,
  GitHubIcon,
  GitLabIcon
} from '../../../icons/icons';
import {
  AZURE_BLUE,
  BITBUCKET_BLUE,
  GITLAB_ORANGE
} from '../../../themes/moderne-theme';

export type PossibleScmConfiguration = Pick<
  ScmConfiguration | RepositoryFragment | WorkerRepositoryFragment,
  '__typename'
>;

type ScmIconProps = {
  configuration: PossibleScmConfiguration;
  SvgIconProps?: SvgIconProps;
};

export const ScmConfigurationIcon: FunctionComponent<ScmIconProps> = ({
  configuration,
  SvgIconProps = {}
}) => {
  const sx = SvgIconProps?.sx || {};
  switch (true) {
    case isScmConfigurationAGithubConfiguration(configuration):
    case isRepositoryAGitHubRepository(configuration):
      return (
        <SvgIcon inheritViewBox {...SvgIconProps} sx={{ ...sx, color: '#000' }}>
          <GitHubIcon />
        </SvgIcon>
      );
    case isScmConfigurationABitbucketConfiguration(configuration):
    case isScmConfigurationABitbucketCloudConfiguration(configuration):
    case isRepositoryABitbucketRepository(configuration):
    case isRepositoryABitbucketCloudRepository(configuration):
      return (
        <SvgIcon
          inheritViewBox
          {...SvgIconProps}
          sx={{ ...sx, color: BITBUCKET_BLUE }}>
          <BitBucketIcon />
        </SvgIcon>
      );
    case isScmConfigurationAGitLabConfiguration(configuration):
    case isRepositoryAGitLabRepository(configuration):
      return (
        <SvgIcon
          inheritViewBox
          {...SvgIconProps}
          sx={{ ...sx, color: GITLAB_ORANGE }}>
          <GitLabIcon />
        </SvgIcon>
      );
    case isScmConfigurationAAzureDevOpsConfiguration(configuration):
    case isRepositoryAAzureDevOpsRepository(configuration):
      return (
        <SvgIcon
          inheritViewBox
          {...SvgIconProps}
          sx={{ ...sx, color: AZURE_BLUE }}>
          <AzureDevOpsIcons />
        </SvgIcon>
      );
    default:
      return null;
  }
};
