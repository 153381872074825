import { Box } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useCommonBreakpoints } from '../../../hooks/use-breakpoints.hooks';

export type ResponsiveDataGridProps = {
  /**
   * `rem`s to add to offset the computed height of the grid
   * @default 2
   */
  autoHeightOffset?: number;
  /**
   * When supplied, will be used to calculate the amount of space below the
   * grid to be reserved when calculating the height of the grid
   */
  footerOffsetSelector?: string;
};
/**
 * Responsive Data Grid
 *
 * Wraps MUI `DataGridPro` and adds responsive behavior through wrapping
 * elements that ensure flex box behavior that grow to fit container.
 *
 * Automatically handles grid density based on device.
 *
 * Automatically calculates the container height from the positional offset
 * plus footer-ish to ensure discoverability of horizontal scrolling options.
 *
 *
 * @param autoHeight {Boolean} - defaults `false` - if `true` the grid will not
 * automatically calculate the height based on the offset of the grid from the
 * top of the page
 */
export const ResponsiveDataGrid: FunctionComponent<
  DataGridProProps & ResponsiveDataGridProps
> = ({
  autoHeightOffset = 2,
  autoHeight = false,
  footerOffsetSelector,
  ...props
}) => {
  const defaultApiRef = useGridApiRef();
  const apiRef = props?.apiRef?.current ? props.apiRef : defaultApiRef;

  const minHeight = 320;
  const [offsetHeight, setOffsetHeight] = useState(minHeight);
  const gridContainerRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(() => {
    if (gridContainerRef?.current) {
      let footerOffset = 0;
      if (footerOffsetSelector) {
        const footerElm = document.querySelector(footerOffsetSelector);
        if (footerElm) {
          footerOffset = footerElm['offsetHeight'];
        }
      }
      const headerOffset = gridContainerRef.current.offsetTop;
      setOffsetHeight(headerOffset + footerOffset);
    }
  }, [footerOffsetSelector]);

  useEffect(() => {
    if (apiRef?.current?.subscribeEvent) {
      handleResize();
      return apiRef?.current?.subscribeEvent('resize', handleResize);
    }
  }, [apiRef, handleResize]);

  const { isMobileOrTablet } = useCommonBreakpoints();

  const autoHeightStyles = !autoHeight
    ? {
        minHeight,
        height: `calc(100vh - (${offsetHeight}px  + ${autoHeightOffset}rem))`,
        overflowX: 'auto',
        overflowY: 'hidden'
      }
    : {};

  return (
    <Box
      className="data-grid-container"
      ref={gridContainerRef}
      sx={{
        width: '100%',
        ...autoHeightStyles
      }}>
      <DataGridPro
        apiRef={apiRef}
        autoHeight={autoHeight}
        autoPageSize={!autoHeight}
        density={isMobileOrTablet ? 'standard' : 'compact'}
        getRowHeight={() => 'auto'}
        {...props}
      />
    </Box>
  );
};
