import { ReactNode } from 'react';

import {
  Alert,
  AlertColor,
  AlertProps,
  AlertTitle,
  Box,
  BoxProps,
  SxProps,
  Theme,
  Typography
} from '@mui/material';

import { FunctionComponentWithChildren } from '@bonsai-components/utility-types';

export const EmptyState: FunctionComponentWithChildren<{
  variant: AlertColor;
  headline?: string | ReactNode;
  body?: string | ReactNode;
  action?: ReactNode;
  /**
   * @deprecated - use `slotProps.box` instead
   */
  sx?: SxProps<Theme>;
  slotProps?: {
    box?: BoxProps;
    alert?: AlertProps;
  };
}> = ({
  headline = undefined,
  body = undefined,
  variant = 'info',
  action = undefined,
  slotProps = {},
  children
}) => (
  <Box {...slotProps?.box}>
    <Alert
      severity={variant}
      variant="outlined"
      action={action}
      {...slotProps?.alert}>
      {headline && <AlertTitle>{headline}</AlertTitle>}
      {body && <Typography variant="body2">{body}</Typography>}
      {children && children}
    </Alert>
  </Box>
);
