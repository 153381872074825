import { NonFunctionProperties } from '../@types';
import { ModerneTenantConfiguration } from '../contexts/config.context';
import { db, ThirdPartyIntegrationDBEntry } from '../helpers/indexedDb.helper';
import { createStore } from './store-creators';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { useThirdPartyIntegration } from '../hooks/use-third-party-integration.hooks';

export const enum ThirdPartyIntegrations {
  OpenAI = 'openai'
}

type ThirdPartyIntegrationsState = {
  integrations: ThirdPartyIntegrationDBEntry[];
  updateIntegration: (
    integration: ThirdPartyIntegrationDBEntry
  ) => Promise<void>;
  /**
   * Use the higher order hook to prevent having to manually pass config:
   *  {@link useThirdPartyIntegration}
   */
  getIntegrationById: (
    id: ThirdPartyIntegrations,
    config: ModerneTenantConfiguration
  ) => ThirdPartyIntegrationDBEntry;
  loadIntegrationsFromIndexedDB: () => Promise<void>;
};

const thirdPartyIntegrationsStateDefaults: NonFunctionProperties<ThirdPartyIntegrationsState> =
  {
    integrations: []
  };

export const useThirdPartyIntegrationStore =
  createStore<ThirdPartyIntegrationsState>((set, get) => {
    return {
      ...thirdPartyIntegrationsStateDefaults,
      loadIntegrationsFromIndexedDB: async () => {
        const allIntegrations = await db.thirdPartyIntegrations.toArray();
        set({ integrations: allIntegrations });
      },
      updateIntegration: async (integration: ThirdPartyIntegrationDBEntry) => {
        await db.thirdPartyIntegrations.put(integration);
        get().loadIntegrationsFromIndexedDB();
      },
      getIntegrationById: (
        id: ThirdPartyIntegrations,
        config: ModerneTenantConfiguration
      ) => {
        if (config?.features?.enableUserIntegrations) {
          const integration = get().integrations.find((i) => i.id === id);
          return integration;
        }
        return null;
      }
    };
  });
