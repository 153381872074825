import { Badge, Box, badgeClasses, styled } from '@mui/material';

export const ScmBadge = styled(Badge)(({ theme }) => ({
  [`& .${badgeClasses.colorSuccess}`]: {
    padding: theme.spacing(0.24),
    fontSize: theme.typography.pxToRem(12),
    minWidth: 'unset',
    height: 'unset',
    minHeight: 'unset',
    backgroundColor: theme.palette.green.dark
  }
}));

/**
 * Does not use the badge component but intended to be used in the same way but
 * as an inline component that simply displays its children as a badge.
 */
export const InlineBadge = styled(Box)(({ theme }) => ({
  borderRadius: 30,
  fontSize: 12,
  padding: `0 ${theme.spacing(1)}`,
  fontWeight: 500
}));
