import { Box, IconButton, SvgIcon, Tooltip } from '@mui/material';
import kebabcase from 'lodash.kebabcase';
import React, { FunctionComponent } from 'react';
import {
  ScmConfiguration,
  useScmConfigurationsQuery
} from '../../__generated__/apollo-hooks';
import { useScmAuthorization } from '../../hooks/use-authorization.hooks';
import { CheckIcon } from '../../icons/icons';
import { ScmBadge } from '../styled-components/badges/badges.styled';
import { ScmConfigurationIcon } from '../utilities/scm-type-icon/scm-type-icon.component';

export const ScmConnections: FunctionComponent = () => {
  const { data } = useScmConfigurationsQuery();

  const { handleAuthorization } = useScmAuthorization();

  const handleClick = (
    configuration: Pick<ScmConfiguration, '__typename' | 'resourceId'>
  ) => {
    handleAuthorization(configuration.resourceId);
  };

  return (
    <React.Fragment>
      {[...(data?.scms || [])]
        ?.sort((a, b) => a.resourceId?.localeCompare(b.resourceId))
        .filter((scm) => scm.valid)
        .map((configuration, i) => {
          const { isAuthorized, resourceId } = configuration;

          return (
            <Box
              key={[configuration.__typename, resourceId, i].join('-')}
              sx={{
                marginRight: 0.66
              }}>
              <Tooltip
                title={
                  isAuthorized
                    ? `Connected to ${configuration.resourceId}`
                    : `Authorize your current session with ${configuration.resourceId}`
                }>
                <ScmBadge
                  overlap="circular"
                  color={isAuthorized ? 'success' : 'default'}
                  invisible={!isAuthorized}
                  badgeContent={
                    isAuthorized && (
                      <SvgIcon
                        inheritViewBox
                        fontSize="inherit"
                        component={CheckIcon}
                      />
                    )
                  }>
                  <IconButton
                    data-testid={`global-scm-connection-${kebabcase(
                      configuration.__typename
                    )}-button`}
                    size="small"
                    disabled={isAuthorized}
                    onClick={() => handleClick(configuration)}>
                    <ScmConfigurationIcon
                      configuration={configuration}
                      SvgIconProps={{
                        sx: {
                          fontSize: '1.1rem'
                        }
                      }}
                    />
                  </IconButton>
                </ScmBadge>
              </Tooltip>
            </Box>
          );
        })}
    </React.Fragment>
  );
};
