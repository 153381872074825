import { PureFunction } from '@bonsai-components/utility-types';

/**
 * Creates a downloadable link to download a blob and then programmatically
 * clicks it and then removes it.
 */
export const downloadBlob = (content: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(content);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

/**
 * Creates a downloadable link to download a string and then programmatically
 * clicks it and then removes it.
 */
export const downloadStringAsFile = (content: string, fileName: string) => {
  const blob = new Blob([content]);
  downloadBlob(blob, fileName);
};

const formatToFileExtension = (format: string): string => {
  switch (format) {
    case 'EXCEL':
      return 'xlsx';
    case 'JSON':
      return 'json';
    case 'CSV':
    default:
      return 'csv';
  }
};

type DownloadMeta = {
  format: string;
  tableId: string;
  recipeRunId: string;
};

export const downloadMetaToFilename: PureFunction<DownloadMeta, string> = ({
  format,
  tableId,
  recipeRunId
}) => {
  const name = tableId.split('.').at(-1);
  return `${name}-${recipeRunId}.${formatToFileExtension(format)}`;
};
