import { useEffect, useState } from 'react';
import { logError } from '../helpers/logger.helper';

export type FetchResponse<T> = {
  data: T | null;
  error: Error | null;
  loading: boolean;
};

export const useFetch = <T>(
  path: string,
  options?: RequestInit
): FetchResponse<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      try {
        const res = await fetch(path, options);
        const data = res.headers.get('content-type').includes('text/plain')
          ? await res.text()
          : await res.json();
        if (data) {
          setData(data);
        }
      } catch (e) {
        logError(e);
        setError(e);
      }
      setLoading(false);
    }

    getData();
  }, [options, path]);

  return { data, error, loading };
};
