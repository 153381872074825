import { useSession } from 'next-auth/react';
import { forwardRef, FunctionComponent } from 'react';

import { ListItemButton, ListItemIcon } from '@mui/material';

import { getAvatarSource } from '../../helpers/avatar.helper';
import { ModerneUserAvatar } from '../styled-components/avatars/avatars.styled';

export const UserAvatar: FunctionComponent = forwardRef((props, ref) => {
  const { data: session } = useSession();
  const avatarSource = getAvatarSource(session);
  const avatarInitials = (
    session?.user?.fullName || session?.user?.userName
  )?.charAt(0);

  return (
    <ListItemButton {...props}>
      <ListItemIcon>
        <ModerneUserAvatar
          {...ref}
          alt={session.user.userName}
          src={avatarSource}
          sx={{
            width: 32,
            height: 32
          }}>
          {avatarInitials}
        </ModerneUserAvatar>
      </ListItemIcon>
    </ListItemButton>
  );
});
