import createGraph from 'ngraph.graph';
import { RecipeDetailsFragment } from '../../__generated__/apollo-hooks';
import { GraphLink, GraphNodeData } from '../../stores/recipe-topology.store';

type NGraphData = {
  nodes: GraphNodeData[];
  links: GraphLink[];
};

export const createNGraphFromData = (data: NGraphData) => {
  const graph = createGraph();

  data.nodes.forEach((node) => {
    graph.addNode(node.id, node.val);
  });

  data.links.forEach((link) => {
    graph.addLink(link.source, link.target);
  });
  return graph;
};

export type TopologyRecipeLike =
  | (RecipeDetailsFragment & { recipeList?: RecipeDetailsFragment[] })
  | null;

export function transformRecipeToNodesAndLinks(
  recipe: TopologyRecipeLike,
  offsetId = 0,
  isPrecondition = false
): {
  nodes: GraphNodeData[];
  links: GraphLink[];
  rootNodeId?: number;
} {
  const nodes: GraphNodeData[] = [];
  const links: GraphLink[] = [];
  let uniqueId = offsetId;

  function traverse(recipe: TopologyRecipeLike, parentId?: number) {
    const nodeId = uniqueId++;

    // Add current recipe as a node
    nodes.push({
      id: nodeId,
      name: recipe?.name,
      val: { ...recipe, recipeList: undefined } as GraphNodeData['val'],
      isPrecondition: isPrecondition && nodeId === offsetId
    });

    // If there's a parent, link current node to its parent
    if (parentId !== undefined) {
      links.push({ source: parentId, target: nodeId });
    }

    // Recursively process each sub-recipe, if any
    recipe?.recipeList?.forEach((subRecipe) => traverse(subRecipe, nodeId));
  }

  traverse(recipe);

  return { nodes, links, rootNodeId: nodes[0]?.id };
}
