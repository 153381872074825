/* eslint-disable no-restricted-imports */
import { Radio } from '@mui/material';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import { PureFunction } from '@bonsai-components/utility-types';
import {
  DataGridProProps,
  GRID_ACTIONS_COLUMN_TYPE,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridApiPro,
  GridFilterModel,
  GridGroupNode,
  GridRowId,
  gridClasses,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import { useOrganizationSelectorGridLazyQuery } from '../../__generated__/apollo-hooks';

import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus';
import { GridProSlotProps } from '@mui/x-data-grid-pro/models/gridProSlotProps';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import {
  useCreateOrEditUserOrganization,
  useExportUserOrganization,
  useRemoveUserOrganization
} from '../../hooks/use-user-organizations.hooks';
import { useSelectedOrganizationStore } from '../../stores/organization-store';
import { ModerneDataGrid } from '../styled-components/data-grids/data-grids.styled';
import {
  getOrganizationColumns,
  groupingColDef
} from './organizations.columns';
import {
  MY_ORGANIZATIONS_GROUPING,
  getOrganizationRows
} from './organizations.rows';
import { MyOrganizationsToolbar } from './organizations.toolbar.component';

const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.path;

const recursivelyCollapseChildren = (row: GridRowId, api: GridApiPro) => {
  const node = api.getRowNode(row);
  if (node.type === 'group' && node.childrenExpanded) {
    // collapse the current row
    api.setRowChildrenExpansion(row, false);
    // recursively collapse the children
    node.children.forEach((node) => recursivelyCollapseChildren(node, api));
  }
};

const isRowSelectable = ({ row: { id } }) => id !== MY_ORGANIZATIONS_GROUPING;

const initialState: Partial<GridInitialStatePro> = {
  pinnedColumns: {
    left: [GRID_ACTIONS_COLUMN_TYPE, GRID_CHECKBOX_SELECTION_COL_DEF.field]
  },
  sorting: {
    sortModel: [{ field: 'isOrganization', sort: 'desc' }]
  },
  columns: {
    columnVisibilityModel: {
      isOrganization: false,
      name: false
    }
  }
};

const organizationSelectorStyles = {
  [`& .${gridClasses.columnSeparator}`]: {
    [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
      display: 'none'
    }
  }
};

const initialSlotProps: GridProSlotProps = {
  loadingOverlay: {
    variant: 'linear-progress',
    noRowsVariant: 'linear-progress'
  },
  baseCheckbox: {
    size: 'small'
  }
};

export const OrganizationsTable: FunctionComponent<{
  onClose?: () => void;
}> = ({ onClose }) => {
  const apiRef = useGridApiRef();
  const { selectedOrganizationId, update } = useSelectedOrganizationStore();
  const exportOrganization = useExportUserOrganization();
  const removeOrganizationModal = useRemoveUserOrganization();
  const [filterModel, setFilterModel] = useState<GridFilterModel>();

  const {
    editUserOrganization,
    copyUserOrganization,
    renderOrganizationModal
  } = useCreateOrEditUserOrganization();

  const [fetchOrganizations, { data, networkStatus }] =
    useOrganizationSelectorGridLazyQuery({
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true
    });

  const loading = isNetworkRequestInFlight(networkStatus);

  useEffect(() => {
    const fetchAllOrganizations = async () => {
      // use a while loop to call `fetchOrganizations` until the data is loaded
      let hasNextPage = true;
      let cursor = undefined;
      while (hasNextPage) {
        const response = await fetchOrganizations({
          variables: {
            after: cursor,
            search:
              filterModel?.quickFilterValues?.length > 0
                ? filterModel.quickFilterValues.join(' ')
                : undefined
          }
        });

        if (response?.data?.organizations?.pageInfo?.hasNextPage) {
          cursor = response.data.organizations.pageInfo.endCursor;
        } else {
          hasNextPage = false;
        }
      }
    };

    fetchAllOrganizations();
  }, [apiRef, fetchOrganizations, filterModel]);

  const rows = useMemo(
    () =>
      getOrganizationRows(
        data?.organizations?.edges?.map(({ node }) => node) ?? []
      ),
    [data?.organizations?.edges]
  );

  const columns = useMemo(
    () =>
      getOrganizationColumns({
        ActionColumnProps: {
          copyOrganization: copyUserOrganization,
          editOrganization: editUserOrganization,
          removeOrganizationModal,
          exportOrganization
        }
      }),
    [
      copyUserOrganization,
      editUserOrganization,
      removeOrganizationModal,
      exportOrganization
    ]
  );

  /**
   * Memoize the expansion status for each node in the grid
   * `api` is not passed to this function so we rely on the `rows` object
   */
  const getExpansionStatus: PureFunction<GridGroupNode, boolean> = useCallback(
    (node) => {
      const selectedRow = rows.find((row) => row.id === selectedOrganizationId);

      // rules:
      // 1. if a quick filter is applied, expand all the nodes
      // 2. always expand my organizations by default
      // 3. if the current selection is a root node, do not expand
      // 4. if the current selection is a child node, expand all the parent nodes
      if (filterModel?.quickFilterValues?.length > 0) {
        return true;
      } else if (node.groupingKey === MY_ORGANIZATIONS_GROUPING) {
        return true;
      } else if (node.depth === 0 && selectedRow?.id === node.id) {
        return false;
      } else {
        return selectedRow?.path?.some((p) => p === String(node.groupingKey));
      }
    },
    [filterModel, rows, selectedOrganizationId]
  );

  useEffect(
    () =>
      apiRef.current.subscribeEvent(
        'rowSelectionCheckboxChange',
        (selectedRow, event) => {
          /**
           * I don't believe we want any further datagrid behavior to occur
           * We just want to intercept the event and update the selected
           * organization
           */
          event.defaultMuiPrevented = true;
          if (selectedRow.value === true) {
            update({ selectedOrganizationId: String(selectedRow.id) });
            if (onClose) {
              onClose();
            }
          }
        }
      ),
    [apiRef, onClose, update]
  );

  useEffect(() => {
    return apiRef?.current?.subscribeEvent(
      'rowExpansionChange',
      (params, _event, details) => {
        const { api } = details as { api: GridApiPro };
        if (!params.childrenExpanded) {
          params.children.forEach((node) =>
            recursivelyCollapseChildren(node, api)
          );
        }
      }
    );
  }, [apiRef]);

  // Optimizations
  const memoizedSelectionModel = useMemo(() => {
    return selectedOrganizationId ? [selectedOrganizationId] : [];
  }, [selectedOrganizationId]);

  return (
    <>
      <ModerneDataGrid
        logLevel="debug"
        // disable options
        disableColumnReorder
        disableColumnSelector
        disableMultipleColumnsSorting
        disableMultipleColumnsFiltering
        autoPageSize={false}
        // data config
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        // loading config
        loading={loading}
        // filtering
        filterMode="server"
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
        // pagination config
        pagination={false}
        // tree config
        treeData={true}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        defaultGroupingExpansionDepth={1}
        isGroupExpandedByDefault={getExpansionStatus}
        // selection config
        checkboxSelection
        rowSelectionModel={memoizedSelectionModel}
        isRowSelectable={isRowSelectable}
        initialState={initialState}
        slots={{
          toolbar: MyOrganizationsToolbar,
          baseCheckbox: Radio,
          footer: () => null
        }}
        slotProps={initialSlotProps}
        sx={organizationSelectorStyles}
      />

      {renderOrganizationModal}
    </>
  );
};
