import { PureFunction } from '@bonsai-components/utility-types';
import { AllRepositoriesQuery } from '../../../__generated__/apollo-hooks';
import { PossibleScmConfiguration } from '../../utilities/scm-type-icon/scm-type-icon.component';

export type UserOrganizationEditorGridRow = {
  id: string;
  // FIXME: should not be optional
  origin?: string;
  organization: string;
  name: string;
  // FIXME: should not be optional
  branch?: string;
  __typename: PossibleScmConfiguration['__typename'];
};

export const determineRepositoryRows: PureFunction<
  AllRepositoriesQuery,
  UserOrganizationEditorGridRow[]
> = (data) =>
  data?.repositories?.edges?.map(({ node }) => ({
    id: node.id,
    origin: node.origin,
    organization: node.organization,
    name: node.name,
    branch: node.branch,
    __typename: node.__typename
  })) || [];
