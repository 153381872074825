/**
 * Gets item from local storage and parses it for valid JSON
 *
 * @param storageItem - the key local storage uses
 * @param fallBack - if there was an error the fallback will be used
 */
export const getObjectFromLocalStorage = <T>(
  storageItem: string,
  fallBack?: T
): T => {
  if (globalThis?.window?.localStorage) {
    let result = fallBack;

    const persistedItemString = localStorage.getItem(storageItem);

    if (persistedItemString) {
      try {
        const persistedItem = JSON.parse(persistedItemString);
        result = persistedItem;
      } catch {
        result = fallBack;
      }
    }

    return result;
  }
  return fallBack;
};
