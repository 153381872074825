import { FunctionComponent } from 'react';
import {
  OrganizationsDocument,
  OrganizationsQueryVariables
} from '../../__generated__/apollo-hooks';
import { SeeHowToRunGraphQL } from '../graphql-explorer/see-how-to-run-graphql.component';

export const OrganizationsApiExample: FunctionComponent = () => {
  return (
    <SeeHowToRunGraphQL<OrganizationsQueryVariables>
      examples={[
        {
          label: 'Organizations',
          operationDocument: OrganizationsDocument,
          operationVariables: {}
        }
      ]}
    />
  );
};
