const termToCrossFieldQuery = (term: string) => {
  const trimmedTerm = term.trim();
  // If the term is already a valid field query, return it as is
  if (/(category:|tags:|shortName:|id:|description:)/g.test(trimmedTerm)) {
    return trimmedTerm;
  }
  return `(${trimmedTerm} OR category:${trimmedTerm} OR tags:${trimmedTerm} OR shortName:${trimmedTerm} OR id:${trimmedTerm} OR description:${trimmedTerm})`;
};

export const multipleTermsToCrossFieldQuery = (query: string) =>
  query.split(/\s+/).map(termToCrossFieldQuery).join(' AND ');

/**
 * Creates a lucene search query from the  search query,
 */
export const buildQuery = (query: string): string => {
  const result = ['*:*'];
  const finalQuery: string = query ? multipleTermsToCrossFieldQuery(query) : '';

  if (finalQuery) {
    result.push(finalQuery);
  }

  return result.join(' AND ');
};
