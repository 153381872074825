import { TablePaginationProps } from '@mui/material';
import {
  DataGridProProps,
  PaginationPropsOverrides
} from '@mui/x-data-grid-pro';
import { FunctionComponent } from 'react';
import { PageInfo } from '../../../__generated__/apollo-hooks';
import {
  ModerneDataGrid,
  ResultsDataGrid
} from '../../styled-components/data-grids/data-grids.styled';
import { CursorPagination } from '../cursor-pagination/cursor-pagination.component';
import { StyledDataGridVariants } from '../data-grid-types';
import { ResponsiveDataGridProps } from '../responsive-data-grid/responsive-data-grid.component';

type ServerPaginatedDataGridProps = {
  variant?: StyledDataGridVariants;
  disablePaginationWhenLoading?: boolean;
  pageInfo: PageInfo;
  /**
   * @deprecated - we use `hasPreviousPage` from `pageInfo` instead.
   */
  cursor?: string;
} & Omit<DataGridProProps, 'paginationMode' | 'component' | 'componentProps'> &
  ResponsiveDataGridProps;

type CustomLoadingProps = {
  loading?: boolean;
} & Partial<TablePaginationProps & PaginationPropsOverrides>;

type PaginatedDataGridProProps = {
  slotProps: {
    pagination: CustomLoadingProps;
  };
} & DataGridProProps;

export const ServerPaginatedDataGrid: FunctionComponent<
  ServerPaginatedDataGridProps
> = ({
  variant = 'standard',
  disablePaginationWhenLoading,
  pageInfo,
  slots,
  slotProps,
  loading,
  ...rest
}) => {
  /* #region pagination */
  const hasNextPage = pageInfo?.hasNextPage;
  const hasPreviousPage = pageInfo?.hasPreviousPage;
  const isPaginated = hasNextPage || hasPreviousPage;
  /* #endregion */

  const combinedProps: PaginatedDataGridProProps = {
    /**
     * Disabled by default as they will require API support to properly
     * implement.
     */
    disableMultipleColumnsFiltering: true,
    disableMultipleColumnsSorting: true,
    paginationMode: 'server',
    pagination: isPaginated,
    loading,
    slots: {
      pagination: CursorPagination,
      ...slots
    },
    slotProps: {
      pagination: {
        hasNextPage,
        hasPreviousPage,
        loading: disablePaginationWhenLoading ? loading : false
      },
      baseButton: {
        color: 'secondary'
      },
      ...slotProps
    },
    ...rest
  };

  if (variant === 'results') {
    return <ResultsDataGrid {...combinedProps} />;
  } else {
    return <ModerneDataGrid {...combinedProps} />;
  }
};
