export const makeRecipeIdCustom = (
  recipeId: string,
  uniqueId: string | number
): string => {
  const parts = recipeId.split('.');
  return parts
    .map((part, index) =>
      index === parts.length - 1 ? `Custom${part}${uniqueId}` : part
    )
    .join('.');
};

export const makeRecipeNameCustom = (recipeName: string): string =>
  `${recipeName} (custom)`;
