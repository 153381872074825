import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material';
import { FunctionComponent } from 'react';

import { signIn } from 'next-auth/react';
import { CenteredBox } from '../../components/styled-components/layouts/layouts.styled';
import { Emoji } from '../../components/utilities/emoji/emoji.component';
import ModerneWordmarkSVG from '../../public/moderne-wordmark.svg';

const ErrorPage: FunctionComponent = () => {
  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          minHeight: 'calc(90vh)'
        }}>
        <Box
          sx={{
            p: 1,
            mt: 5,
            color: 'white',
            backgroundColor: (theme) => theme.palette.indigo[500],
            borderRadius: 1,
            boxShadow: '0px 6px 8px 0px #0000000D;'
          }}>
          <CenteredBox
            sx={{
              mt: 5,
              mb: 3
            }}>
            <SvgIcon
              viewBox="0 0 152 23"
              sx={{
                fill: 'white',
                height: 46 * 0.66,
                width: 304 * 0.66
              }}
              component={ModerneWordmarkSVG}
            />
          </CenteredBox>
          <Box
            sx={{
              mb: 5
            }}>
            <Stack
              direction="column"
              sx={{
                alignItems: 'center'
              }}>
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'center'
                }}>
                <Emoji name="thinking" />
                Well, that was unexpected.
              </Typography>
              <Typography variant="body1">
                Please try to sign in again
              </Typography>
              <Typography
                sx={{
                  mt: 4,
                  width: '10rem'
                }}>
                <Button
                  variant="contained"
                  fullWidth={true}
                  size="large"
                  onClick={() => {
                    signIn('keycloak', { callbackUrl: window.location.origin });
                  }}>
                  Sign in
                </Button>
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ErrorPage;
