import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Recipe } from '../../__generated__/apollo-hooks';
import { SEARCH_INPUT_ID } from '../../constants/general';
import { SEARCH_BAR_PLACEHOLDER } from '../../constants/messages';
import { focusFirstFocusable } from '../../helpers/focus.helper';
import {
  recipeDetailsUrl,
  searchForRecipeUrl
} from '../../helpers/link.helper';
import { useStandardDebouncedCallback } from '../../hooks/use-standard-debounce.hooks';
import { SearchIcon } from '../../icons/icons';
import { useUserPreferenceStore } from '../../stores/user-preference.store';
import { SearchInputField } from './global-search.styled';
import { PreviousSearchQueries } from './previous-search-queries.component';
import { SearchRecipes } from './search-recipes.component';

export const AutoCompleteSearch: FunctionComponent<{
  toggleMenu?: () => void;
}> = ({ toggleMenu }) => {
  const { previousSearchQueries = [], update } = useUserPreferenceStore();
  const router = useRouter();

  const contentRef = useRef<HTMLElement>(null);

  const methods = useForm<{
    query: string;
  }>();

  const queryValue = methods.watch('query');

  const resetState = () => {
    methods.resetField('query');
    if (toggleMenu) {
      toggleMenu();
    }
  };

  const handleInputChange = useStandardDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.target.value.trim() !== '') {
        methods.setValue('query', e.target.value);
      } else {
        methods.resetField('query');
      }
    }
  );

  const handleRedirect = (input: string | Recipe): void => {
    if (typeof input === 'string') {
      router.push(searchForRecipeUrl(input.trim()));
    } else if (typeof input !== 'string' && input?.id) {
      router.push(recipeDetailsUrl({ id: input.id }));
    } else {
      return null;
    }
  };

  const updateRecentSearchQueries = (query: string) => {
    if (query) {
      update({
        previousSearchQueries: Array.from(
          new Set([query.trim(), ...previousSearchQueries])
        ).slice(0, 5)
      });
    }
  };

  const handleBlur = () => {
    if (queryValue?.trim() !== '') {
      updateRecentSearchQueries(queryValue);
    }
  };

  const handleFormSubmit = (data) => {
    if (!handleInputChange.isPending() || data.override) {
      updateRecentSearchQueries(data.query);
      handleRedirect(data.query || '');
      resetState();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if ((e.key === 'Tab' && e.shiftKey) || e.key === 'Escape') {
      e.preventDefault();
      return resetState();
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      return handleFormSubmit({
        query: (e.target as HTMLInputElement).value,
        override: true
      });
    }
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      return focusFirstFocusable(contentRef.current);
    }
  };

  const showRecipeResults = Boolean(
    queryValue?.trim() && queryValue.trim() !== ''
  );
  const showPreviousTerms =
    Boolean(!queryValue || queryValue.trim() === '') &&
    previousSearchQueries.length > 0;

  return (
    <FormProvider {...methods}>
      <Box p={1}>
        <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
          <SearchInputField
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            {...methods.register('query', {})}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            id={SEARCH_INPUT_ID}
            fullWidth
            autoComplete="off"
            placeholder={SEARCH_BAR_PLACEHOLDER}
            inputProps={{
              'data-testid': 'global-search-input'
            }}
            endAdornment={<SearchIcon color="action" />}
          />
        </form>
      </Box>
      <Box ref={contentRef}>
        {showPreviousTerms && <PreviousSearchQueries />}
        {showRecipeResults && (
          <SearchRecipes
            searchTerm={queryValue}
            resetModal={resetState}
            toggleMenu={toggleMenu}
          />
        )}
      </Box>
    </FormProvider>
  );
};
