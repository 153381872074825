import { split } from 'emoji-aware';
import { emojiToName, nameToEmoji } from 'gemoji';

import { Base64EncodedString } from '@bonsai-components/utility-types';

// eslint-disable-next-line no-control-regex
const REGEX_EMOJI = /[^\u0000-\u00ff]/u; // REGEX: unicode emoji is present

export type DefaultParam = {
  name: string;
  value: number | string | boolean | string[];
}[];

/**
 * Recommended methods for browser-based encoding base64 strings with utf-8 characters
 * @see https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_1_–_escaping_the_string_before_encoding_it
 */
export const utf8ToB64 = <T>(str: string): T => {
  return window.btoa(unescape(encodeURIComponent(str))) as T;
};

/**
 * Recommended methods for browser-based decoding base64 strings with utf-8 chars
 * @see https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_1_–_escaping_the_string_before_encoding_it
 */
export const b64ToUtf8 = (str: string | Base64EncodedString): string => {
  return decodeURIComponent(escape(window.atob(str)));
};

/**
 * encode a json object or array of json objects as base64 using btoa
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa
 */
export const encodeJSONBase64 = <T>(jsonObject: T): Base64EncodedString =>
  utf8ToB64<Base64EncodedString>(JSON.stringify(jsonObject));

/**
 * decode a string (base64) into a json object or array of json objects using atob
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
 */
export const decodeJSONBase64 = <T>(str: Base64EncodedString): T => {
  // defense against parsing error
  try {
    if (typeof window !== 'undefined') {
      return JSON.parse(b64ToUtf8(str)) as T;
    } else {
      return JSON.parse(Buffer.from(str, 'base64').toString('utf-8')) as T;
    }
  } catch {
    return null;
  }
};

/**
 * Takes a recipe options array and returns an encoded string representing
 * an array of objects with the names and values.
 * Original intent was to encode recipe defaults and reduce size of resultant
 */
export const encodeRecipeDefaults = (
  defaults: DefaultParam
): Base64EncodedString =>
  encodeJSONBase64(
    defaults
      .filter((option) => option?.value !== null)
      .map(({ name, value }) => ({ name, value }))
  );

/**
 * Universal helper to encode a string as a base64 string
 */
export const toBase64 = (value: string): Base64EncodedString => {
  const demojifiedValue = replaceUnicodeEmojiWithShortcode(value);

  if (typeof window !== 'undefined') {
    return utf8ToB64<Base64EncodedString>(demojifiedValue);
  }

  return Buffer.from(demojifiedValue, 'utf-8').toString(
    'base64'
  ) as Base64EncodedString;
};

export const fromBase64 = (value: Base64EncodedString): string => {
  if (typeof window !== 'undefined') {
    let decodedValue;

    try {
      decodedValue = b64ToUtf8(value);
    } catch {
      return null;
    }

    const matches = decodedValue.match(/:([a-z_]+):/g);

    for (const match of matches || []) {
      decodedValue = decodedValue.replaceAll(
        match,
        nameToEmoji[match.replaceAll(':', '')]
      );
    }
    return decodedValue;
  }

  return Buffer.from(value, 'base64').toString('utf-8');
};

/**
 * Takes a string and replaces unicode emoji with a shortcode
 * example: 🍣 -> :sushi:
 */
export const replaceUnicodeEmojiWithShortcode = (str: string): string => {
  return split(str)
    .map((each) => (REGEX_EMOJI.test(each) ? `:${emojiToName[each]}:` : each))
    .join('');
};

export const base64ImageSrc = (
  base64String: Base64EncodedString,
  mimeType = 'image/png'
): string => `data:${mimeType};base64, ${base64String}`;
