import 'graphiql/graphiql.css';

import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Base64EncodedString } from '@bonsai-components/utility-types';
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import {
  Autocomplete,
  Dialog,
  DialogContent,
  FormHelperText,
  IconButton,
  TextField,
  Typography
} from '@mui/material';

import { ToolbarButton } from '@graphiql/react';
import GraphiQL, { GraphiQLProps } from 'graphiql';
import {
  bindDialog,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import { ShareGraphQLCurl } from '../components/graphql-explorer/share-graphql-curl.component';
import { ShareGraphiQL } from '../components/graphql-explorer/share-graphql-query.component';
import { Layout } from '../components/layout/layout.component';
import { GraphiQLContainer } from '../components/styled-components/tools/graphiql.styled';
import { ClosableDialogTitle } from '../components/utilities/dialog/closable-dialog-title.component';
import { MenuBase } from '../components/utilities/menus/menu-base/menu-base.component';
import { MAX_FORM_WIDTH } from '../constants/forms';
import { ConfigurationContext } from '../contexts/config.context';
import { unique } from '../helpers/array.helper';
import { b64ToUtf8, decodeJSONBase64 } from '../helpers/encoding.helper';
import { logError } from '../helpers/logger.helper';
import { ConfigureIcon, ShareIcon } from '../icons/icons';

export const commonGraphiQlConfigurations: Partial<GraphiQLProps> = {
  forcedTheme: 'light',
  disableTabs: true,
  defaultEditorToolsVisibility: 'variables',
  isHeadersEditorEnabled: true,
  showPersistHeadersSettings: false
};

type GraphQLPageQuery = {
  query?: Base64EncodedString;
  variables?: Base64EncodedString;
  url?: Base64EncodedString;
};
export const GraphQL: FunctionComponent = () => {
  const { getGraphQlUrl } = useContext(ConfigurationContext);

  const router = useRouter();
  const { query, variables, url } = router.query as GraphQLPageQuery;
  const decodedUrl = url ? decodeURIComponent(url) : null;
  const fetchUrl = url ? decodedUrl : getGraphQlUrl();

  const { control, watch } = useForm<{ url: string }>({
    mode: 'onBlur',
    defaultValues: {
      url: fetchUrl
    }
  });

  const graphqlUrl = watch('url');

  const fetcher = createGraphiQLFetcher({
    url: graphqlUrl
  });

  const { data: session } = useSession();

  const editFormState = usePopupState({
    variant: 'dialog',
    popupId: 'editForm'
  });

  let initialQuery;
  let initialVariables;

  try {
    initialQuery = b64ToUtf8(query) ?? undefined;
    initialVariables = decodeJSONBase64(variables) ?? undefined;
  } catch (error) {
    logError(error);
  }

  const graphqlEndpoints = unique([getGraphQlUrl(), decodedUrl]).filter(
    Boolean
  );

  return (
    <Layout
      title="API Explorer"
      pageTitle="API Explorer"
      isLoading={Boolean(!session?.accessToken)}>
      <GraphiQLContainer>
        {decodedUrl && graphqlEndpoints?.length > 1 && (
          <React.Fragment>
            {editFormState.isOpen && (
              <Dialog {...bindDialog(editFormState)} maxWidth={'md'}>
                <ClosableDialogTitle
                  title="GraphQL URL"
                  onClose={editFormState.close}
                />
                <DialogContent>
                  <Controller
                    name={'url'}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        freeSolo
                        value={value}
                        onChange={(_event, newValue) => onChange(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select a different GraphQL end-point"
                            size="small"
                            variant="outlined"
                            sx={{
                              width: MAX_FORM_WIDTH
                            }}
                          />
                        )}
                        options={graphqlEndpoints}
                      />
                    )}
                  />
                  <FormHelperText>
                    When querying for the patches for recipe results or
                    repository details, you will need to use a GraphQL end-point
                    that has <code>/worker/graphql</code> and contain the query
                    string parameter with the repository path.
                  </FormHelperText>
                </DialogContent>
              </Dialog>
            )}
            <Typography
              variant="body2"
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 0.5
              }}>
              <strong>URL:</strong> {graphqlUrl}
              <IconButton
                {...bindTrigger(editFormState)}
                sx={{ fontSize: 'inherit' }}>
                <ConfigureIcon fontSize="inherit" />
              </IconButton>
            </Typography>
          </React.Fragment>
        )}
        <GraphiQL
          {...commonGraphiQlConfigurations}
          // configurations
          fetcher={fetcher}
          query={initialQuery}
          variables={initialVariables}
          headers={JSON.stringify({
            Authorization: `Bearer ${session?.accessToken}`
          })}
          toolbar={{
            additionalContent: (
              <MenuBase
                menuId={'share'}
                actionItem={
                  <ToolbarButton label={'Share'}>
                    <ShareIcon className="graphiql-toolbar-icon" />
                  </ToolbarButton>
                }>
                <ShareGraphiQL url={graphqlUrl} />
                <ShareGraphQLCurl url={graphqlUrl} />
              </MenuBase>
            )
          }}
        />
      </GraphiQLContainer>
    </Layout>
  );
};

export default GraphQL;
