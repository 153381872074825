import { ConfirmOptions } from 'material-ui-confirm';

export const defaultConfirmationProps: ConfirmOptions = {
  contentProps: {},
  confirmationButtonProps: {
    autoFocus: true,
    size: 'small',
    variant: 'contained'
  },
  cancellationButtonProps: {
    color: 'secondary',
    size: 'small',
    variant: 'text'
  },
  allowClose: true
};
