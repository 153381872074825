export const not = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

export const union = (a, b) => {
  return [...a, ...not(b, a)];
};

export const unique = (arr) => {
  return arr.filter((v, i, a) => a.indexOf(v) === i);
};
