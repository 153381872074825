import { Avatar, styled } from '@mui/material';

export const ToolAvatar = styled(Avatar)`
  width: 34px;
  height: 34px;
`;

export const ModerneUserAvatar = styled(Avatar)(({ theme }) => ({
  color: theme.palette.indigo[450],
  borderWidth: theme.spacing(0.25),
  borderStyle: 'solid',
  borderColor: theme.palette.indigo[450],
  fontSize: theme.typography.pxToRem(14)
}));
