import { PureFunction } from '@bonsai-components/utility-types';
import {
  GRID_CHECKBOX_SELECTION_FIELD,
  GridFilterModel,
  GridSortModel
} from '@mui/x-data-grid-pro';
import {
  RepositoryFields,
  RepositoryFilter
} from '../../../__generated__/apollo-hooks';
import { OrganizationsRowData } from './organization-repositories.rows';

export const sortModelToRepositoryFilter: PureFunction<
  GridSortModel,
  Pick<RepositoryFilter, 'sortBy' | 'sortOrder'>
> = (sortModel) => {
  if (sortModel?.length > 0) {
    return {
      sortBy: mapColumnToRepositoryField(sortModel[0].field),
      sortOrder: sortModel[0].sort === 'asc' ? 'ASC' : 'DESC'
    };
  }
};
export const filterModelToRepositoryFilter: PureFunction<
  GridFilterModel,
  Pick<RepositoryFilter, 'filterBy' | 'filterByQuery' | 'query'>
> = (filterModel) => {
  if (
    filterModel?.quickFilterValues?.length > 0 ||
    (filterModel?.items.length > 0 &&
      filterModel?.items[0].field !== GRID_CHECKBOX_SELECTION_FIELD)
  ) {
    return {
      query: filterModel?.quickFilterValues?.join(' '),
      filterByQuery: filterModel?.items[0]?.value,
      filterBy: mapColumnToRepositoryField(filterModel?.items[0]?.field)
    };
  }
};

const mapColumnToRepositoryField = (
  field: Omit<keyof OrganizationsRowData, 'id' | 'type' | 'orphaned'>
): RepositoryFields => {
  switch (field) {
    case 'origin':
      return 'ORIGIN';
    case 'path':
      return 'PATH';
    case 'branch':
      return 'BRANCH';
    case 'ingested':
      return 'INGESTED';
    case 'weight':
      return 'WEIGHT';
    case 'name':
      return 'NAME';
    case 'organization':
      return 'ORGANIZATION';
    case 'changeset':
      return 'CHANGESET';
  }
};
