import { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import {
  GridToolbarContainer,
  GridToolbarProps,
  GridToolbarQuickFilter,
  ToolbarPropsOverrides
} from '@mui/x-data-grid-pro';

export const GridToolbarWithQuickFilter: FunctionComponentWithChildren<
  GridToolbarProps & ToolbarPropsOverrides
> = ({ children }) => (
  <GridToolbarContainer>
    <GridToolbarQuickFilter />
    {children}
  </GridToolbarContainer>
);
