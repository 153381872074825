import { usePopupState } from 'material-ui-popup-state/hooks';
import { FunctionComponent, useContext } from 'react';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuList,
  SvgIcon,
  Typography
} from '@mui/material';

import {
  CHANGELOG_PAGE_URL,
  DOCUMENTATION_SITE_URL,
  SUPPORT_EMAIL
} from '../../../constants/general';
import { ConfigurationContext } from '../../../contexts/config.context';
import { createSupportEmailUrl } from '../../../helpers/link.helper';
import { getTenantName } from '../../../helpers/next.helpers';
import { dedent } from '../../../helpers/string.helper';
import {
  DiscordIcon,
  EmailIcon,
  HelpIcon,
  SlackIcon
} from '../../../icons/icons';
import {
  MenuDivider,
  NavigationMenuItem,
  NavigationMenuItemButton
} from '../../account-menu/account-menu.styled';
import { ModerneCli } from '../../account-menu/moderne-cli/moderne-cli.component';
import { SectionHeading } from '../../global-search/global-search.styled';
import { FlexBox } from '../../styled-components/layouts/layouts.styled';
import { Link } from '../../utilities/moderne-link/moderne-link.component';
import { MenuItemWithSubMenu } from '../left-nav/menu-with-sub-menu.component';
import { LeftNavMenuProps } from '../left-nav/navigation-items';

export const HelpMenu = () => {
  const popupMenu = usePopupState({
    variant: 'popover',
    popupId: 'help-menu'
  });

  const menuItem: LeftNavMenuProps = {
    icon: <SvgIcon component={HelpIcon} inheritViewBox />,
    label: 'Help',
    description: 'Help and support'
  };

  return (
    <MenuItemWithSubMenu
      menuItem={menuItem}
      popoverState={popupMenu}
      slotProps={{
        menuProps: {
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          sx: {
            left: '0.5rem',
            bottom: 0,
            top: '41px'
          }
        }
      }}>
      <SectionHeading
        sx={{
          minWidth: '15rem'
        }}>
        Resources
      </SectionHeading>
      <MenuList>
        <NavigationMenuItem>
          <NavigationMenuItemButton
            title="Documentation on how to use the features of Moderne"
            href={DOCUMENTATION_SITE_URL}
            external
            aria-label="Documentation on how to use the features of Moderne">
            Documentation
          </NavigationMenuItemButton>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuItemButton
            href={CHANGELOG_PAGE_URL}
            external
            title="Changelog"
            aria-label="Changelog">
            Changelog
          </NavigationMenuItemButton>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuItemButton
            title="Provide feedback on how we can improve"
            external
            href={createSupportEmailUrl({
              subject: `[Feedback][${getTenantName()}] - Enter subject`,
              body: dedent(`
              Feedback:
              <-- Need help? Found a bug? Have a suggestion? Let us know! -->
              
              Screenshots:
              <!-- Include screenshots if applicable.  -->
              `)
            })}
            aria-label="Provide feedback on how we can improve">
            Feedback
          </NavigationMenuItemButton>
        </NavigationMenuItem>
      </MenuList>
      <SectionHeading
        sx={{
          minWidth: '15rem'
        }}>
        Tools
      </SectionHeading>
      <MenuList>
        <ModerneCli />
      </MenuList>
      <SectionHeading
        sx={{
          minWidth: '15rem'
        }}>
        Need more help?
      </SectionHeading>
      <MenuList>
        <ListItem>
          <ListItemIcon sx={{ minWidth: '2rem' }}>
            <SlackIcon fontSize="small" htmlColor="black" />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: 'caption'
            }}>
            <Link
              external
              linkText={'OpenRewrite Slack'}
              href={`https://join.slack.com/t/rewriteoss/shared_invite/zt-nj42n3ea-b~62rIHzb3Vo0E1APKCXEA`}
            />
          </ListItemText>
        </ListItem>

        <ListItem>
          <ListItemIcon sx={{ minWidth: '2rem' }}>
            <DiscordIcon fontSize="small" htmlColor="black" />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: 'caption'
            }}>
            <Link
              external
              linkText={'OpenRewrite Discord'}
              href={`https://discord.gg/xk3ZKrhWAb`}
            />
          </ListItemText>
        </ListItem>

        <ListItem>
          <ListItemIcon sx={{ minWidth: '2rem' }}>
            <EmailIcon fontSize="small" htmlColor="black" />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: 'caption'
            }}>
            <Link
              external
              linkText={SUPPORT_EMAIL}
              href={`mailto:${SUPPORT_EMAIL}`}
            />
          </ListItemText>
        </ListItem>
      </MenuList>
      <MenuDivider />
      <FlexBox
        sx={{
          mx: 2,
          mb: 1
        }}>
        <ModerneVersion />
      </FlexBox>
    </MenuItemWithSubMenu>
  );
};

const ModerneVersion: FunctionComponent = () => {
  const {
    config: { version }
  } = useContext(ConfigurationContext);

  return (
    <FlexBox
      sx={{
        flexDirection: 'column',
        alignItems: 'start'
      }}>
      <Typography variant="caption">
        &copy; {new Date().getFullYear()} Moderne, Inc.
      </Typography>
      {version && (
        <Typography
          variant="caption"
          sx={{
            color: 'secondary'
          }}>
          Version:{' '}
          <Typography component="code" variant="inherit">
            {version}
          </Typography>
        </Typography>
      )}
    </FlexBox>
  );
};
