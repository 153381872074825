import { GridApiPro } from '@mui/x-data-grid-pro';
import {
  FunctionComponent,
  MutableRefObject,
  useEffect,
  useState
} from 'react';
import { useFormContext } from 'react-hook-form';
import {
  CreateUserOrganizationDocument,
  CreateUserOrganizationMutationVariables,
  RepositoryFragment,
  UpdateUserOrganizationDocument,
  UpdateUserOrganizationMutationVariables
} from '../../../__generated__/apollo-hooks';
import { repositoryIdToRepositoryFragment } from '../../../helpers/repository.helpers';
import { SeeHowToRunGraphQL } from '../../graphql-explorer/see-how-to-run-graphql.component';
import { UserOrganizationFormValues } from './user-organization-editor.component';

type UserOrganizationApiExampleProps = {
  apiRef: MutableRefObject<GridApiPro>;
  immutableRepositories?: RepositoryFragment[];
  id?: string;
};

export const UserOrganizationApiExample: FunctionComponent<
  UserOrganizationApiExampleProps
> = ({ apiRef, immutableRepositories, id }) => {
  const [selectedRepositoryList, setSelectedRepositoryList] = useState<
    Pick<RepositoryFragment, 'origin' | 'path' | 'branch'>[]
  >(immutableRepositories || []);

  const { watch } = useFormContext<UserOrganizationFormValues>();
  const name = watch('name');
  const description = watch('description');

  useEffect(() => {
    return apiRef?.current?.subscribeEvent?.('rowsSet', () => {
      const repositories = immutableRepositories
        ? immutableRepositories
        : Array.from(apiRef?.current?.getSelectedRows?.()?.keys() || []).map(
            repositoryIdToRepositoryFragment
          );

      setSelectedRepositoryList(repositories);
    });
  }, [apiRef, immutableRepositories]);

  const userOrganization = {
    name,
    description,
    repositories: selectedRepositoryList.map(({ origin, path, branch }) => {
      return {
        origin,
        path,
        branch
      };
    })
  };

  if (id) {
    return (
      <SeeHowToRunGraphQL<UpdateUserOrganizationMutationVariables>
        examples={[
          {
            label: 'Update user organization',
            operationDocument: UpdateUserOrganizationDocument,
            operationVariables: {
              userOrganization: { id, ...userOrganization }
            }
          }
        ]}
      />
    );
  }

  return (
    <SeeHowToRunGraphQL<CreateUserOrganizationMutationVariables>
      examples={[
        {
          label: 'Create user organization',
          operationDocument: CreateUserOrganizationDocument,
          operationVariables: {
            userOrganization
          }
        }
      ]}
    />
  );
};
