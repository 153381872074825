import {
  listClasses,
  listItemButtonClasses,
  listItemClasses,
  listItemIconClasses,
  listItemTextClasses,
  styled
} from '@mui/material';
import { NAVIGATION_RAIL_WIDTH } from '../../../constants/general';

const MENU_ITEM_COLOR = '#1C1B1F';
const SELECTED_COLOR = '#003fbd';
const SELECTED_COLOR_BACKGROUND = '#3776F01A';

export const LeftNavigationRail = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  maxWidth: NAVIGATION_RAIL_WIDTH,
  justifyContent: 'space-between',
  height: '100vh',
  overflowY: 'auto',
  borderRight: '1px solid',
  borderColor: theme.palette.divider,
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [`& .${listClasses.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  [`& .${listItemClasses.root}`]: {
    padding: 0
  },
  [`& .${listItemButtonClasses.root}`]: {
    flexDirection: 'column',
    color: MENU_ITEM_COLOR
  },
  [`& .${listItemButtonClasses.root}:hover`]: {
    borderRadius: theme.shape.borderRadius
  },
  [`& .${listItemButtonClasses.selected}`]: {
    background: SELECTED_COLOR_BACKGROUND,
    color: SELECTED_COLOR,
    [`& .${listItemIconClasses.root}`]: {
      color: SELECTED_COLOR
    }
  },
  [`& .${listItemTextClasses.root}`]: {
    margin: 0
  },
  [`& .${listItemTextClasses.primary}`]: {
    fontSize: theme.typography.pxToRem(10),
    whiteSpace: 'normal',
    textAlign: 'center'
  },
  [`& .${listItemIconClasses.root}`]: {
    color: theme.palette.common.black,
    minWidth: 24
  }
}));
