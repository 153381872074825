import { PureFunction } from '@bonsai-components/utility-types';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsColDef,
  GridCallbackDetails,
  GridColDef,
  GridColTypeDef,
  GridFilterModel,
  GridFilterOperator,
  GridPinnedColumnFields,
  GridRenderCellParams,
  getGridStringOperators
} from '@mui/x-data-grid-pro';
// eslint-disable-next-line no-restricted-imports
import { GridStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';

import { dateRangeFilterOperator } from '../components/data-grid/filter-operators/date-range.filter-operator';
import { NoRowsOverlayProps } from '../components/data-grid/no-rows-overlay/no-rows-overlay.component';
import { LocaleNumber } from '../components/utilities/locale-number/locale-number.component';

export enum FilterOperator {
  StartsWith = 'startsWith',
  EndsWith = 'endsWith',
  Equals = 'equals',
  IsEmpty = 'isEmpty',
  IsNotEmpty = 'isNotEmpty',
  NotContains = 'notContains',
  Contains = 'contains',
  DateRange = 'dateRange',
  MyRuns = 'myRuns'
}

const supportedGridStringFilterOperatorNames: string[] = [
  FilterOperator.StartsWith,
  FilterOperator.EndsWith,
  FilterOperator.Equals,
  FilterOperator.IsEmpty,
  FilterOperator.IsNotEmpty,
  FilterOperator.NotContains,
  FilterOperator.Contains
];

const supportedGridStringFilterOperators: GridFilterOperator[] =
  getGridStringOperators().filter((op) =>
    supportedGridStringFilterOperatorNames.includes(op.value)
  );

export const getSupportedGridStringFilterOperators = (
  names?: string[],
  omit?: boolean
): GridFilterOperator[] => {
  if (!names || names.length === 0) {
    return supportedGridStringFilterOperators;
  }
  return supportedGridStringFilterOperators.filter((op) =>
    omit ? !names.includes(op.value) : names.includes(op.value)
  );
};

export enum FilterComparator {
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  EQUALS = 'EQUALS',
  IS_EMPTY = 'IS_EMPTY',
  NOT_EMPTY = 'NOT_EMPTY',
  NOT_CONTAINS = 'NOT_CONTAINS',
  DATE_RANGE = 'DATE_RANGE',
  CONTAINS = 'CONTAINS'
}

export type DataGridColumnSizes =
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'full';

type DataGridColumnSizeHelper = Partial<Pick<GridColDef, 'minWidth' | 'flex'>>;

const FLEX_BASE = 0.125;
const COLUMN_BASE = 100;

export const dataGridColumnSize = (
  size?: DataGridColumnSizes
): DataGridColumnSizeHelper => {
  switch (size) {
    case 'full':
      return {
        minWidth: COLUMN_BASE * 2, // 200px
        flex: FLEX_BASE * 8 // 1
      };
    case 'large':
      return {
        minWidth: COLUMN_BASE * 1.75,
        flex: FLEX_BASE * 4
      };
    case 'medium':
      return {
        minWidth: COLUMN_BASE * 1.5,
        flex: FLEX_BASE * 2
      };
    case 'small':
      return {
        minWidth: COLUMN_BASE * 1.25,
        flex: FLEX_BASE
      };

    case 'xsmall':
      return {
        minWidth: COLUMN_BASE * 0.875,
        flex: FLEX_BASE
      };

    default:
      return {
        minWidth: COLUMN_BASE
      };
  }
};

// Grid Column Definitions

type LocaleNumberGridColProps = {
  humanReadableAbbreviation: boolean;
};

export const localeNumberGridCol: PureFunction<
  LocaleNumberGridColProps,
  GridColTypeDef
> = ({ humanReadableAbbreviation }) => ({
  type: 'number',
  renderCell: ({ value }: GridRenderCellParams): string =>
    (value && (
      <LocaleNumber value={value} humanReadable={humanReadableAbbreviation} />
    )) ||
    value
});

export const dateTimeFilterableGridCol: GridColTypeDef = {
  type: 'date',
  filterable: true,
  filterOperators: [dateRangeFilterOperator],
  valueGetter: (value) => {
    return value ? new Date(value) : null;
  }
};

export const filterlessSortlessGridCol: GridColTypeDef = {
  filterable: false,
  sortable: false,
  disableColumnMenu: true
};

export const buttonGridCol: GridColTypeDef = {
  type: 'string',
  sortable: false,
  align: 'center',
  headerAlign: 'center'
};

export const actionsGridCol: Omit<GridActionsColDef, 'getActions' | 'field'> = {
  type: 'actions',
  headerName: 'Actions',
  filterable: false,
  sortable: false,
  align: 'center',
  headerAlign: 'center'
};

export const pinnedColumnConfig: Pick<GridStatePro, 'pinnedColumns'> = {
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions']
  }
};

export const mergePinnedColumnsWithDefaults: PureFunction<
  GridPinnedColumnFields,
  GridPinnedColumnFields
> = (pinnedColumns) => ({
  ...pinnedColumnConfig.pinnedColumns,
  ...pinnedColumns
});

export const getNoRowsOverlayMessage: PureFunction<
  NoRowsOverlayProps,
  NoRowsOverlayProps
> = (props) => props;

export const removeRowsWithoutId = (row) => row?.id;

export const hasValidFilterModel = (
  filterModel: GridFilterModel,
  details: GridCallbackDetails
): boolean => {
  return (
    (details?.reason &&
      // you can have a filter model with items but no values
      // this happens with the filter panel is first opened but before input
      // from the user is entered
      filterModel.items.some((item) => item?.value !== undefined)) ||
    filterModel.items.length === 0
  );
};
