import { LinkProps, Link as MuiLink } from '@mui/material';
import NextLink from 'next/link';
import React from 'react';

export type ModerneLinkProps = {
  href: string;
  linkText?: string;
  external?: boolean;
} & LinkProps;

/**
 *
 * @param {string} linkText - Will take precedence over any `children` passed
 * @returns {React.ReactNode} - Internal NextJS or external link
 */
export const Link = React.forwardRef<HTMLAnchorElement, ModerneLinkProps>(
  (
    { href, linkText = undefined, external = false, children, ...linkProps },
    ref
  ) => (
    <MuiLink
      href={href}
      component={external ? undefined : NextLink}
      target={external ? '_blank' : undefined}
      rel={external ? 'noreferrer' : undefined}
      underline="hover"
      {...linkProps}
      ref={ref}
      sx={[
        {
          color: 'primary'
        },
        ...(Array.isArray(linkProps.sx) ? linkProps.sx : [linkProps.sx])
      ]}>
      {linkText || children}
    </MuiLink>
  )
);
