import React, { FunctionComponent } from 'react';

import {
  ButtonGroup,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  IconButton
} from '@mui/material';

import { CloseIcon } from '../../../icons/icons';
import { CenteredOpposingBoxes } from '../../styled-components/layouts/layouts.styled';

export const ClosableDialogTitle: FunctionComponent<
  {
    title: string;
    onClose: () => void | DialogProps['onClose'];
    secondaryButton?: React.ReactNode;
  } & DialogTitleProps
> = ({ title, secondaryButton, onClose, ...titleProps }) => (
  <CenteredOpposingBoxes>
    <DialogTitle {...titleProps}>{title}</DialogTitle>
    <ButtonGroup sx={{ margin: 1 }}>
      {secondaryButton}
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </ButtonGroup>
  </CenteredOpposingBoxes>
);
