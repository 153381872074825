import { ApolloQueryResult } from '@apollo/client';
import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { PAGE_SIZE_DEFAULT } from '../constants/general';
import { logError } from '../helpers/logger.helper';

type CursorPaginationHook<T> = {
  dataKey: keyof T | string;
  fieldKey?: string;
  refetch: (variables?: unknown) => Promise<ApolloQueryResult<T>>;
  initialPage?: number;
  pageSize?: number;
};

type CursorPaginationHookReturn = {
  pagesNextCursor: MutableRefObject<{
    [page: number]: string;
  }>;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  handlePaginationModelChange: (pageModel: GridPaginationModel) => void;
};

export const useCursorPagination = <T>({
  dataKey,
  fieldKey = null,
  refetch,
  initialPage = 0,
  pageSize = PAGE_SIZE_DEFAULT
}: CursorPaginationHook<T>): CursorPaginationHookReturn => {
  const pagesNextCursor = useRef<{ [page: number]: string }>({});
  const [currentPage, setCurrentPage] = useState(initialPage);

  const fetchAndSetCursor = async (newPage: number) => {
    const nextCursor = pagesNextCursor.current[newPage - 1];

    // TODO: can i set this to >= 0 instead and not break anything?
    if (!nextCursor && newPage > 0) {
      setCurrentPage(newPage);
      return;
    }

    try {
      const response = await refetch({
        after: nextCursor
      });

      if (
        fieldKey &&
        response.data?.[dataKey as string]?.[fieldKey].pageInfo.endCursor
      ) {
        pagesNextCursor.current[newPage] =
          response.data[dataKey as string][fieldKey].pageInfo.endCursor;
      } else if (
        !fieldKey &&
        response.data?.[dataKey as string].pageInfo.endCursor
      ) {
        pagesNextCursor.current[newPage] =
          response.data?.[dataKey as string].pageInfo.endCursor;
      }
    } catch (e) {
      logError(e);
    }
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchAndSetCursor(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaginationModelChange = (pageModel: GridPaginationModel) => {
    const { page: newPage } = pageModel;

    if (newPage === 0 || pagesNextCursor.current[newPage - 1]) {
      fetchAndSetCursor(newPage);
    }
  };

  return {
    pagesNextCursor,
    paginationModel: { page: currentPage, pageSize },
    handlePaginationModelChange
  };
};
