import { Box, Button } from '@mui/material';
import React, { Component, ReactElement, ReactNode } from 'react';
import {
  ConfigurationContext,
  ConfigurationContextState
} from '../../../contexts/config.context';
import {
  createSupportEmailUrl,
  markdownCodeBlock
} from '../../../helpers/link.helper';
import { logError } from '../../../helpers/logger.helper';
import { getTenantName } from '../../../helpers/next.helpers';
import { DebugIcon } from '../../../icons/icons';
import { CodeSnippet } from '../../code-snippet/code-snippet.component';
import { Layout } from '../../layout/layout.component';
import { CenteredBox } from '../../styled-components/layouts/layouts.styled';

export type ErrorBoundaryProps = {
  children: ReactElement;
};
export type ErrorBoundaryState = {
  hasError: boolean;
  error?: { message: string; stack?: string };
};

export class PageErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: { message: null, stack: null } };
  }

  static contextType?: React.Context<ConfigurationContextState> =
    ConfigurationContext;
  context: ConfigurationContextState;

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }
  componentDidCatch(
    error: Error | { name: string; message: string },
    errorInfo: never
  ): void {
    // You can also log the error to an error reporting service
    this.setState({
      error: typeof error === 'string' ? { message: error, stack: '' } : error
    });
    logError(error, errorInfo);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Layout
          isLoading={false}
          title="Page error"
          maxWidth="md"
          pageTitle="An unexpected error occurred">
          <CenteredBox
            sx={{
              flexDirection: 'column'
            }}>
            <CodeSnippet copyButtonPlacement="top" color="light">
              {this.state.error.stack}
            </CodeSnippet>
            <Box
              sx={{
                my: 2
              }}>
              <Button
                href={createSupportEmailUrl({
                  subject: `[🐛][${getTenantName()}] - ${this.state.error.message}`,
                  body:
                    (this.state.error.stack
                      ? markdownCodeBlock(this.state.error.stack)
                      : '') +
                    '\n\n' +
                    window.location.href
                })}
                variant="contained"
                color="primary"
                startIcon={<DebugIcon />}>
                Report a bug
              </Button>
            </Box>
          </CenteredBox>
        </Layout>
      );
    }

    return this.props.children;
  }
}
