import { LOCAL_STORAGE_USER_PREFERENCES } from '../constants/general';
import { getObjectFromLocalStorage } from '../helpers/local-storage.helper';
import { createPersistedStore } from './store-creators';

const LOCAL_STORAGE_UI_FEATURES = 'ui-features' as const;

type UiFeaturesStore = {
  useAiSearch: boolean; // => features
};

const initialization = (obj) => {
  if (!globalThis?.window?.localStorage) {
    return obj;
  }
  // read from local storage old values
  const old = getObjectFromLocalStorage<Partial<UiFeaturesStore>>(
    LOCAL_STORAGE_USER_PREFERENCES,
    {}
  );

  // update new local storage key with old values
  if (!old || old.useAiSearch === undefined) {
    return obj;
  }

  const { useAiSearch } = old;

  delete old.useAiSearch;

  localStorage.setItem(LOCAL_STORAGE_USER_PREFERENCES, JSON.stringify(old));
  return {
    ...obj,
    useAiSearch
  };
};

export const useUiFeaturesStore = createPersistedStore<UiFeaturesStore>(
  () => ({
    useAiSearch: true
  }),
  {
    name: LOCAL_STORAGE_UI_FEATURES,
    version: 1
  },
  initialization
);
