import { useEffect, useRef } from 'react';
import { focusFirstFocusable } from '../helpers/focus.helper';

/**
 * Returns a ref that you can attach to a container element.  When the container
 * element mounts it will focus the first focusable element within the container.
 */
export const useFocusFirstFocusable = () => {
  const containerRef = useRef<Element>(null);
  /**
   * on mount sets focus
   */
  useEffect(() => {
    focusFirstFocusable(containerRef.current);
  }, []);

  return containerRef;
};
