import { MarkdownString } from '../../../@types';
import { dedent } from '../../../helpers/string.helper';
import { LinuxIcon, MacIcon, WindowsIcon } from '../../../icons/icons';
import {
  Distribution,
  InstallKind,
  getCurlCommand,
  getPowerShellCommand
} from './moderne-cli.helpers';

export const DISTRIBUTIONS: Distribution[] = [
  {
    key: 'windows',
    label: 'Windows',
    icon: WindowsIcon,
    fileExtension: 'exe',
    installCommands: [
      {
        key: 'powershell',
        label: 'PowerShell',
        command: getPowerShellCommand
      },
      {
        key: 'git-bash',
        label: 'git-bash',
        command: getCurlCommand
      },
      {
        key: 'chocolatey',
        label: 'Chocolatey',
        command: ({ environment, version }) => ({
          kind: InstallKind.CODE,
          command: () =>
            dedent(
              `choco install mod ${
                environment === 'staging'
                  ? '--prerelease'
                  : `--prerelease --version=${version}`
              }`
            )
        }),
        description:
          'To install using [Chocolatey](https://chocolatey.org), run the command from the command line or from PowerShell.' as MarkdownString
      },
      {
        key: 'maven-central',
        label: 'Maven Central (JAR)',
        command: ({ version }) => {
          // replace leading `v`
          const rawVersion = version?.replace(/^v/, '');
          return {
            kind: InstallKind.MARKDOWN,
            command: dedent<MarkdownString>(`
          1. [Download ${rawVersion} from Maven Central](https://repo1.maven.org/maven2/io/moderne/moderne-cli/${rawVersion}/moderne-cli-${rawVersion}.jar) or browse all versions on [Maven Central](https://central.sonatype.com/artifact/io.moderne/moderne-cli/versions)
          2. Create a script called _mod_ in your path that runs the jar

          \`\`\`bash
          # git-bash
          mod() {
            java -jar /path/to/mod.jar "$@"
          }
          \`\`\`
          
          \`\`\`powershell
          # or in PowerShell
          PS> Set-Alias -Name mod -Value "java -jar /path/to/mod.jar"
          \`\`\``)
          };
        }
      }
    ]
  },
  {
    key: 'macos-tar',
    label: 'MacOS',
    icon: MacIcon,
    fileExtension: 'tar.gz',
    installCommands: [
      {
        key: 'macos-tar',
        label: 'curl (MacOS)',
        command: getCurlCommand
      },
      {
        key: 'homebrew',
        label: 'Homebrew',
        command: ({ environment }) => ({
          kind: InstallKind.CODE,
          command: () =>
            dedent(
              `brew install moderneinc/moderne/mod ${
                environment === 'staging' ? '--head' : ''
              }`
            )
        }),
        description:
          'To install on MacOS using [Homebrew](https://brew.sh), run the command from the command line / terminal.' as MarkdownString
      }
    ]
  },
  {
    key: 'linux-tar',
    label: 'Linux',
    icon: LinuxIcon,
    fileExtension: 'tar.gz',
    installCommands: [
      {
        key: 'linux-tar',
        label: 'curl (Linux)',
        command: getCurlCommand
      }
    ]
  }
];
