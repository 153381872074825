import { CommandWithKeyBinding } from '../../../contexts/command-palette.context';
import { KeyboardShortcut } from '../../keyboard-shortcuts/keyboard-shortcuts.component';

type SearchCommandShortcutSettings = {
  disabled?: true;
  defaultFor?: 'command-menu' | 'search';
  shortcut: React.ReactNode;
  bindings: CommandWithKeyBinding[];
}[];

export const searchCommandShortcutSettings: SearchCommandShortcutSettings = [
  {
    defaultFor: 'command-menu',
    shortcut: (
      <KeyboardShortcut
        shortcut={{
          key: 'K',
          modifier: { alt: true, meta: true }
        }}
      />
    ),
    bindings: [
      {
        key: '˚',
        modifier: { meta: true },
        includeInputMode: true
      },
      {
        code: 'KeyK',
        modifier: { alt: true, ctrl: true },
        includeInputMode: true
      }
    ]
  },
  {
    shortcut: (
      <KeyboardShortcut
        shortcut={{
          key: 'K',
          modifier: { shift: true, meta: true }
        }}
      />
    ),
    bindings: [
      {
        key: 'K',
        modifier: { meta: true },
        includeInputMode: true
      },
      {
        code: 'KeyK',
        modifier: { shift: true, ctrl: true },
        includeInputMode: true
      }
    ]
  },
  {
    shortcut: (
      <KeyboardShortcut
        shortcut={{
          key: 'K',
          modifier: { meta: true }
        }}
      />
    ),
    bindings: [
      {
        key: 'k',
        modifier: { meta: true },
        includeInputMode: true
      }
    ]
  },
  {
    shortcut: (
      <KeyboardShortcut
        shortcut={{
          key: 'K',
          modifier: { ctrl: true }
        }}
      />
    ),
    bindings: [
      {
        key: 'k',
        modifier: { ctrl: true },
        includeInputMode: true
      }
    ]
  },
  {
    defaultFor: 'search',
    shortcut: (
      <KeyboardShortcut
        shortcut={{
          key: '/'
        }}
      />
    ),
    bindings: [
      {
        key: '/'
      }
    ]
  },
  // There should only be one disabled option
  {
    disabled: true,
    shortcut: 'Disabled',
    bindings: []
  }
];

export const disabledIndexForShortcutSettings =
  searchCommandShortcutSettings.findIndex((c) => c.disabled);

export const commandMenuIndexForShortcutSettings =
  searchCommandShortcutSettings.findIndex(
    (c) => c.defaultFor === 'command-menu'
  );

export const searchIndexForShortcutSettings =
  searchCommandShortcutSettings.findIndex((c) => c.defaultFor === 'search');
