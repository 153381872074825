import { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Tab,
  TabProps,
  Tabs,
  TabsProps,
  Tooltip,
  styled,
  tabClasses
} from '@mui/material';
import { FunctionComponent } from 'react';
import { Link } from '../../utilities/moderne-link/moderne-link.component';

export const ModerneTabs: FunctionComponentWithChildren<TabsProps> = ({
  children,
  ...rest
}) => (
  <TabContext value={rest.value}>
    <Tabs {...rest}>{children}</Tabs>
  </TabContext>
);

const BaseModerneTab = styled((props: TabProps) => (
  <Tab LinkComponent={Link} {...props} />
))(({ theme }) => ({
  color: theme.palette.indigo[600],
  [`&.${tabClasses.selected}`]: {
    backgroundColor: theme.palette.background.paper,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    color: theme.palette.indigo[600],
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderBottom: 0
  }
}));

export type ModerneTabProps = {
  href?: string;
  /**
   * The text to be displayed in a tooltip when the tab is disabled.
   */
  disabledTitle?: string | React.ReactNode;
  /**
   * The data test id to be applied to the tab.
   */
  ['data-testid']: string;
} & TabProps;

/**
 * `ModerneTab` serves a few purposes:
 * - Adds a tooltip to the tab when it is disabled.
 * - Applies a Moderne-specific style to the selected tab.
 * - Provides a work-around for a type error with a styled-component instance
 * of `Tab` that has a `href` for a prop
 */
export const ModerneTab: FunctionComponent<ModerneTabProps> = ({
  disabledTitle,
  ...props
}) =>
  props.disabled && disabledTitle ? (
    <Tooltip title={disabledTitle} arrow>
      <span>
        <BaseModerneTab {...props} />
      </span>
    </Tooltip>
  ) : (
    <BaseModerneTab {...props} />
  );

export const ModerneTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: 0,
  paddingTop: theme.spacing(2)
}));
